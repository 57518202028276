import { BdsButton, BdsTypo } from 'blip-ds/dist/blip-ds-react';

interface Props {
  linkPressed: () => void;
  linkTitle: string;
}

export const WhatsappActivationFooter = ({ linkPressed, linkTitle }: Props) => {
  return (
    <BdsButton
      size="standard"
      variant="tertiary"
      icon="agent"
      onBdsClick={() => linkPressed()}
      data-testid="footer-link"
    >
      {linkTitle}
    </BdsButton>
  );
};
