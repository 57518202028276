import { BdsModal } from 'blip-ds/dist/blip-ds-react';
import { IsManualFreemium } from 'constants/FeatureToggle';
import { PAYWALL_ROUTE } from 'constants/Routes';
import { Analytics, Logger } from 'infra/adapters';
import { PlanTypeEnum } from 'objects/types/BlipGoApi';
import { RefObject } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useProject } from 'redux/slices/ProjectSlice/projectSlice';
import { getPlanName } from 'utils/Plans';
import { BasicModalContent } from './components/BasicModalContent/BasicModalContent';
import { ProModalContent } from './components/ProModalContent/ProModalContent';

interface IProPlanModal {
  modalRef: RefObject<HTMLBdsModalElement>;
  activeMessageOrigin?: boolean;
}

export const ProPlanModal = ({ modalRef, activeMessageOrigin }: IProPlanModal) => {
  const className = 'ProPlanModal';
  const navigate = useNavigate();
  const intentPlanType = localStorage.getItem('intentPlanType');
  const { planType } = useSelector(useProject);

  const closeModal = (tracking: string, modalRef: RefObject<HTMLBdsModalElement>) => {
    Analytics.Track(tracking, {
      origin: activeMessageOrigin ? 'Active Messages' : 'Channel Activation',
      planType: getPlanName(planType),
    });

    modalRef?.current?.toggle?.();
  };

  const handleCloseModal = () => {
    const methodName = 'handleCloseModal';
    if (modalRef?.current) {
      if (IsManualFreemium && !activeMessageOrigin) {
        if (planType === PlanTypeEnum.Basic) {
          closeModal(Analytics.events.CLOSE_MODAL_PRO_PLAN, modalRef);
          Logger.Info('closing pro plan modal', { methodName, className });
        } else {
          switch (intentPlanType) {
            case 'basic':
            case 'free':
              closeModal(Analytics.events.CLOSE_MODAL_BASIC_PLAN, modalRef);
              Logger.Info('closing basic plan modal', { methodName, className });
              break;
            case 'pro':
              closeModal(Analytics.events.CLOSE_MODAL_PRO_PLAN, modalRef);
              Logger.Info('closing pro plan modal', { methodName, className });
              break;
            default:
              closeModal(Analytics.events.CLOSE_MODAL_PRO_PLAN, modalRef);
              break;
          }
        }
      } else {
        closeModal(Analytics.events.CLOSE_MODAL_PRO_PLAN, modalRef);
        Logger.Info('closing pro plan modal', { methodName, className });
      }
    }
  };

  const handlePlanDirection = (tracking: string) => {
    const methodName = 'handlePlanDirection';
    Analytics.Track(tracking, {
      origin: activeMessageOrigin ? 'Active Messages' : 'Channel Activation',
      planType: getPlanName(planType),
    });
    Logger.Info('redirecting user to paywall...', { methodName, className });
    navigate(PAYWALL_ROUTE, { state: { userRegistered: false } });
  };

  return (
    <BdsModal ref={modalRef} size="dynamic" closeButton={false}>
      {activeMessageOrigin ? (
        <ProModalContent
          activeMessageOrigin={activeMessageOrigin}
          handleCloseModal={handleCloseModal}
          handlePlanDirection={() => handlePlanDirection(Analytics.events.CLICK_MODAL_SIGNATURE_PLAN_PRO)}
        />
      ) : (
        <BasicModalContent
          intentPlanType={intentPlanType}
          handleCloseModal={handleCloseModal}
          handlePlanDirection={() => handlePlanDirection(Analytics.events.CLICK_MODAL_SIGNATURE_PLAN_BASIC)}
        />
      )}
    </BdsModal>
  );
};
