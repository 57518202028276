import { novemberUserPacks, novemberUserToken } from 'configurations/Environment';
import { MAIL_TEMPLATE } from 'constants/EmailBody';
import { MailApi } from 'infra/adapters';

export const sendEmailTeam = async (
  fromName: string,
  tos: {
    toName: string;
    toMailAddress: string;
  },
  subject: string,
  body: string,
  isHtml: any,
) => {
  const mailApi = new MailApi('sendEmailTeam');

  const toMailAddress = tos.toMailAddress;
  const toName = tos.toName;
  const formData = new FormData();
  formData.append('fromName', fromName);
  formData.append('tos[0].toName', toName);
  formData.append('tos[0].toMailAddress', toMailAddress);
  formData.append('subject', subject);
  formData.append('body', body);
  formData.append('isHtml', isHtml);
  const response = await mailApi.Post('v1/Mail', formData, {
    params: { fromName, tos, subject, body, isHtml },
    auth: {
      username: novemberUserPacks,
      password: novemberUserToken,
    },
  });
  return response;
};

export const formatUpgradeWAMail = (
  userName: string,
  email: string,
  billingEmail: string,
  companyName: string,
  cnpj: string,
  site: string,
  plan: string,
  agentPosition?: string,
  connectionChoice?: string,
): string => {
  let html = MAIL_TEMPLATE.html.code;
  html = html.replace(
    'userData',
    `
      <li> Nome: ${userName}</li>
      <li> E-mail: ${email}</li>
      <li> E-mail de Cobrança: ${billingEmail}</li>
      <li> Empresa: ${companyName}</li>
      <li> CPNJ: ${cnpj}</li>
      <li> Site: ${site}</li>
      <li> Cargo do representante: ${agentPosition ?? 'Não informado'}</li>
      <li> Plano contratado: ${plan}</li>
      <li> Escolha de ativação: ${connectionChoice ?? 'Sem escolha'} </li>
      `,
  );
  return html;
};
