import { BdsButton, BdsChipTag, BdsGrid, BdsTypo } from 'blip-ds/dist/blip-ds-react';
import ExternalUrls from 'constants/ExternalUrls';
import { SIGNATURE_ROUTE } from 'constants/Routes';
import { PLAN_UPGRADE_INFO } from 'constants/WppModalInfo';
import { Analytics, Logger } from 'infra/adapters';
import { PlanTypeEnum } from 'objects/types/BlipGoApi';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useProject } from 'redux/slices/ProjectSlice/projectSlice';
import { PaywallTableLine } from './Components/PaywallTableLine/PaywallTableLine';
import { PaywallContainer, PaywallTableDivider, ScrollableTableContainer } from './styles';

const className = 'PaywallV2';

export const PaywallV2 = () => {
  const navigate = useNavigate();
  const { planType } = useSelector(useProject);

  const openProContractInfo = (planType: PlanTypeEnum) => {
    Analytics.Track(Analytics.events.PAYWALL_UPGRADE_PLAN);
    navigate(SIGNATURE_ROUTE, { state: { planType } });
  };

  const openBasicContractInfo = (planType: PlanTypeEnum) => {
    Analytics.Track(Analytics.events.SIGNATURE_PLAN_BASIC_PAYWALL);
    navigate(SIGNATURE_ROUTE, { state: { planType } });
  };

  const openLearnMore = () => {
    const methodName = 'OpenLearmore';
    Analytics.Track(Analytics.events.PAYWALL_LEARN_MORE);
    window.open(
      `${ExternalUrls.whatsAppExperienceCluster}Tenho dúvidas sobre o plano PRO Blip Go e gostaria de falar com um especialista para saber mais.`,
      '_blank',
    );
    Logger.Info('redirecting to experience cluster chat...', { methodName, className });
  };

  const TableHeader = (
    <BdsGrid justifyContent="space-between">
      <BdsTypo variant="fs-32" bold="bold" className="paywall-title">
        Assine um plano e atenda seus clientes no WhatsApp com Blip Go!
      </BdsTypo>
      <BdsGrid className="paywall-header-container">
        <BdsGrid direction="column" justifyContent="center" alignItems="center" className="paywall-free-header">
          <BdsTypo variant="fs-32" bold="bold" margin={false} className="paywall-plan-name">
            Basic
          </BdsTypo>
          <BdsTypo variant="fs-16" margin={false} className="spacing-price-plan">
            Grátis
          </BdsTypo>
          {planType === PlanTypeEnum.Free ? (
            <BdsButton
              arrow={true}
              onBdsClick={() => openBasicContractInfo(PlanTypeEnum.Basic)}
              variant="outline"
              color="content"
              data-testid="paywall-contract-plan-basic-btn"
            >
              Assinar plano Basic
            </BdsButton>
          ) : (
            <BdsChipTag color="info">Seu plano atual</BdsChipTag>
          )}
        </BdsGrid>
        <BdsGrid direction="column" justifyContent="center" alignItems="center" className="paywall-pro-header">
          <BdsTypo variant="fs-32" bold="bold" className="white-color paywall-plan-name" margin={false}>
            PRO
          </BdsTypo>
          <BdsTypo variant="fs-16" className="white-color spacing-price-plan">
            R$ 399,00 /mês
          </BdsTypo>
          <BdsButton
            arrow={true}
            onBdsClick={() => openProContractInfo(PlanTypeEnum.Pro)}
            variant="solid"
            color="content"
            data-testid="paywall-contract-plan-pro-btn"
          >
            Assinar o Plano PRO
          </BdsButton>
        </BdsGrid>
      </BdsGrid>
    </BdsGrid>
  );

  return (
    <PaywallContainer>
      <BdsGrid direction="column" gap="2" className="paywall-main-grid">
        <ScrollableTableContainer>
          <BdsGrid direction="column">
            {TableHeader}
            {PLAN_UPGRADE_INFO.map((feature, index) => {
              return (
                <div key={feature?.label}>
                  <PaywallTableLine
                    proIncluded={feature?.proIncluded}
                    freeIncluded={feature?.freeIncluded}
                    featureLabel={feature?.label}
                    additionalInfoFree={feature?.additionalInfoFree}
                    additionalInfoPro={feature?.additionalInfoPro}
                    showWhatsappLogo={feature?.showWhatsappLogo}
                    tooltipText={feature?.tooltipText}
                  />
                  {index !== PLAN_UPGRADE_INFO.length - 1 && <PaywallTableDivider />}
                </div>
              );
            })}
            <BdsGrid justifyContent="space-between" margin="t-2">
              <BdsGrid>
                <BdsTypo variant="fs-10" bold="bold">
                  Cancele a qualquer momento, sem multas ou tempo minímo de contrato.
                </BdsTypo>
                <BdsTypo variant="fs-10">Use o Blip Go! porque gostou. 🤟</BdsTypo>
              </BdsGrid>
              <BdsButton variant="tertiary" onClick={openLearnMore} icon="agent" data-testid="paywall-learn-more-btn">
                Falar com especialista
              </BdsButton>
            </BdsGrid>
          </BdsGrid>
        </ScrollableTableContainer>
      </BdsGrid>
    </PaywallContainer>
  );
};
