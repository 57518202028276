import { Link } from 'react-router-dom';

import styled from 'styled-components';

export const MainContainer = styled.div`
  width: 980px;
  height: 784px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  padding: 32px;
  gap: 40px;
`;

export const BottomContainer = styled.div`
  width: 100%;
  height: 90px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const BoxImageBanner = styled.div`
  width: 12.5rem;
  height: 12.5rem;
`;

export const BoxTextBanner = styled.div`
  width: 30.5rem;
  height: 9.25rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 1rem;
`;

export const LinkNewUrl = styled(Link)`
  text-decoration: underline;
  color: #454545;

  &:hover {
    color: #454545;
  }
`;

export const LinkPhoneNumber = styled(Link)`
  text-decoration: none;
  font-weight: 700;
  color: #454545;

  &:hover {
    color: #454545;
  }
`;
