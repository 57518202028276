import styled from 'styled-components';

export const MainContainer = styled.div`
  width: 980px;
  height: 784px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  padding: 32px;
  gap: 40px;

  .typo {
    margin: false;
  }
`;

export const MetaLink = styled.span`
  color: var(color-surface-primary);
  text-decoration: underline;
  cursor: pointer;
`;
