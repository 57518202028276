import { BdsButton, BdsGrid, BdsIllustration, BdsPaper, BdsTypo } from 'blip-ds/dist/blip-ds-react';
import { Analytics } from 'infra/adapters';
import './style.scss';
import { useNavigate } from 'react-router-dom';
import { ACTIVE_MESSAGES_CREATE_TEMPLATE } from 'constants/Routes';

export const ActiveMessagesNotRegistered = () => {
  const navigate = useNavigate();

  const handleCreateTemplate = () => {
    Analytics.Track(Analytics.events.ACTIVE_MESSAGES_SCREEN_EMPTY_CREATE);
    navigate(ACTIVE_MESSAGES_CREATE_TEMPLATE);
  };

  return (
    <>
      <BdsPaper style={{ background: '#FFF' }}>
        <BdsGrid
          direction="column"
          justifyContent="center"
          alignItems="center"
          padding="5"
          gap="3"
          style={{ width: '100%' }}
        >
          <BdsGrid alignItems="center" gap="2">
            <BdsIllustration className="active-messages-not-registered-ilustrator" name="empty" type="spots" />
          </BdsGrid>
          <BdsGrid alignItems="center" gap="2">
            <BdsTypo variant="fs-20" margin={false} bold="bold">
              Nenhuma mensagem cadastrada
            </BdsTypo>
          </BdsGrid>
          <BdsGrid alignItems="center" gap="2">
            <BdsTypo
              className="active-messages-not-registered-text-create-template"
              variant="fs-16"
              margin={false}
              bold="regular"
            >
              Para fazer disparo em massa, é necessário cadastrar uma mensagem que passará pela aprovação do WhatsApp.
            </BdsTypo>
          </BdsGrid>
          <BdsGrid alignItems="center" gap="2">
            <BdsButton
              onBdsClick={() => handleCreateTemplate()}
              variant="outline"
              color="primary"
              data-testid="button-create-template"
            >
              Cadastrar nova mensagem
            </BdsButton>
          </BdsGrid>
        </BdsGrid>
      </BdsPaper>
    </>
  );
};
