import {
  BdsButton,
  BdsCheckbox,
  BdsGrid,
  BdsIcon,
  BdsIllustration,
  BdsModal,
  BdsPaper,
  BdsTypo,
} from 'blip-ds/dist/blip-ds-react';
import { META_CONNECTION_ROUTE } from 'constants/Routes';
import { Analytics, Logger } from 'infra/adapters';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import YouTube from 'react-youtube';
import './style.scss';
import { Container } from './styles';

const className = 'NumberPreparation';

const NumberPreparation: React.FC = () => {
  const methodName = 'NumberPreparation';

  const [confirmationButtonDisabled, setConfirmationButtonDisabled] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    Analytics.Track(Analytics.events.NUMBER_PREPARATION_OPEN);
    Logger.Info('Number Preparation page loaded', { methodName, className });
  }, []);

  const openMetaConnectionPage = (trackEvent: string) => {
    const methodName = 'openMetaConnectionPage';
    Analytics.Track(trackEvent);
    navigate(META_CONNECTION_ROUTE);
    Logger.Info(`redirecting to meta conection page (${META_CONNECTION_ROUTE})`, { methodName, className });
  };

  const showModalConfirmation = () => {
    const modal = document.getElementById('number-ready-confirmation') as HTMLBdsModalElement;
    if (modal) {
      modal.toggle?.();
    }
  };

  const closeModalModalConfirmation = () => {
    const modal = document.getElementById('number-ready-confirmation') as HTMLBdsModalElement;
    modal.toggle?.();
  };

  return (
    <Container>
      <BdsGrid className="number-preparation-container" direction="column" gap="4" container-fluid padding="half">
        <BdsGrid gap="4" direction="column">
          <BdsTypo variant="fs-24" bold="bold" margin={false}>
            Defina como irá seguir com a ativação
          </BdsTypo>
          <BdsGrid direction="row" padding="none" gap="5">
            <BdsPaper className="option-card">
              <BdsGrid direction="column" gap="1" alignItems="center">
                <BdsIcon type="icon" size="brand" name="whatsapp" />
                <BdsTypo variant="fs-20" bold="bold" margin={false} className="option-title">
                  Manter um número já existente
                </BdsTypo>
                <BdsTypo variant="fs-16" margin={false} className="option-text">
                  O número já está sendo utilizado para atendimento no WhatsApp e quero mante-lo ativo
                </BdsTypo>
                <BdsButton
                  className="option-button"
                  block={true}
                  size="standard"
                  type="button"
                  variant="primary"
                  onBdsClick={() => showModalConfirmation()}
                  data-testid="number-deleted-btn"
                >
                  Seguir com número existente
                </BdsButton>
              </BdsGrid>
            </BdsPaper>
            <BdsPaper className="option-card">
              <BdsGrid direction="column" gap="1" alignItems="center">
                <BdsIcon type="icon" size="brand" name="chip" />
                <BdsTypo variant="fs-20" bold="bold" margin={false} className="option-title">
                  Usar um número novo
                </BdsTypo>
                <BdsTypo variant="fs-16" margin={false} className="option-text">
                  O número de telefone foi comprado recentemente e <b>nunca foi conectado ao WhatsApp</b>
                </BdsTypo>
                <BdsButton
                  className="option-button"
                  block={true}
                  size="standard"
                  type="button"
                  variant="primary"
                  onBdsClick={() => openMetaConnectionPage(Analytics.events.NUMBER_PREPARATION_REGISTER_NEW_PHONE)}
                  data-testid="new-phone-btn"
                >
                  Seguir com novo número
                </BdsButton>
              </BdsGrid>
            </BdsPaper>
          </BdsGrid>
        </BdsGrid>
      </BdsGrid>
      <BdsModal id="number-ready-confirmation" size="dynamic">
        <BdsGrid direction="column" gap="3">
          <BdsGrid alignItems="center" gap="2">
            <BdsIllustration className="attention-image" type="default" name="atention" />
            <BdsTypo className="modal-title" variant="fs-20" bold="bold" lineHeight="plus" margin={false}>
              Antes de continuar, confirme que seu número está pronto
            </BdsTypo>
          </BdsGrid>
          <BdsGrid direction="column" gap="half">
            <BdsTypo variant="fs-16" bold="bold" margin={false}>
              Apague o número do aplicativo
            </BdsTypo>
            <BdsTypo className="modal-text" variant="fs-14" margin={false}>
              A API do WhatsApp é diferente do aplicativo convencional, e não é possível usar o mesmo número em ambos ao
              mesmo tempo. Por isso, é necessário <b>excluir o número do aplicativo para conectar com a API</b>.
            </BdsTypo>
          </BdsGrid>
          <BdsGrid gap="2" className="video-number-preparation">
            <YouTube videoId="CvEmiiinF8Q" className="video-style" />
          </BdsGrid>

          <BdsCheckbox
            onBdsChange={() => setConfirmationButtonDisabled(!confirmationButtonDisabled)}
            label={'Já apaguei a conta do WhatsApp e estou com número disponível'}
            name={'confirmation'}
            refer=""
            data-testid="confirmation-checkbox"
          />
          <BdsButton
            className="confirmation-button"
            block={true}
            size="standard"
            type="button"
            variant="primary"
            disabled={confirmationButtonDisabled}
            onBdsClick={() => {
              closeModalModalConfirmation();
              openMetaConnectionPage(Analytics.events.NUMBER_PREPARATION_DELETED_PHONE);
            }}
            data-testid="confirmation-button"
          >
            Iniciar ativação do WhatsApp
          </BdsButton>
        </BdsGrid>
      </BdsModal>
    </Container>
  );
};

export default NumberPreparation;
