import { BdsIcon, BdsTooltip } from 'blip-ds/dist/blip-ds-react';
import { SelectHour } from 'components/SelectHour/SelectHour';
import { Shift } from 'objects/types/humamService/AttendanceHour';
import ConfigurationsModuleTexts from '../../../../../utils/texts/ConfigurationsModuleTexts.json';
import { HoursContainer, MainContainer } from './styles';

interface TurnHoursProps {
  index: number;
  shiftsQuantity: number;
  shift: Shift;
  isDisabled: boolean;
  handleAddShift: () => void;
  handleRemoveShift: (i: number) => void;
  weekDay?: string;
  handleShiftChange: (index: number, updatedShift: Shift) => void;
}

export const TurnHours = ({
  index,
  shiftsQuantity,
  shift,
  isDisabled,
  handleAddShift,
  handleRemoveShift,
  weekDay,
  handleShiftChange,
}: TurnHoursProps) => {
  const shouldShowAddIcon = shiftsQuantity < 3 && index === shiftsQuantity - 1;
  const shouldShowRemoveIcon = shiftsQuantity > 1 && index === shiftsQuantity - 1;

  const updatePeriod = (isStart: boolean, period: string) => {
    const updatedShift = { ...shift, [isStart ? 'startTime' : 'endTime']: period };
    handleShiftChange(index, updatedShift);
  };

  return (
    <MainContainer $index={index} $showRemoveIcon={shouldShowRemoveIcon}>
      <HoursContainer>
        <SelectHour
          value={isDisabled ? '' : shift.startTime}
          setAttendance={period => updatePeriod(true, period)}
          disabled={isDisabled}
          text={ConfigurationsModuleTexts.schedules.times.start}
          end={0}
          dataTestId={`testStart${weekDay}`}
        />

        <SelectHour
          value={isDisabled ? '' : shift.endTime}
          setAttendance={period => updatePeriod(false, period)}
          disabled={isDisabled}
          text={ConfigurationsModuleTexts.schedules.times.end}
          end={1}
          dataTestId={`testEnd${weekDay}`}
        />
      </HoursContainer>

      {shouldShowAddIcon && (
        <BdsTooltip
          className="tooltip"
          position="left-center"
          tooltip-text={ConfigurationsModuleTexts.schedules.button.add}
        >
          <BdsIcon
            className="icon"
            name="add"
            size="medium"
            onClick={handleAddShift}
            data-testid={`addshift${weekDay}`}
          />
        </BdsTooltip>
      )}

      {shouldShowRemoveIcon && (
        <BdsTooltip
          className="tooltip"
          class="icon-remove"
          position="left-center"
          tooltip-text={ConfigurationsModuleTexts.schedules.button.remove}
        >
          <BdsIcon
            className="icon"
            name="delete"
            size="medium"
            onClick={() => handleRemoveShift(index)}
            data-testid={`removeTurn${weekDay}`}
          />
        </BdsTooltip>
      )}
    </MainContainer>
  );
};
