import { BdsGrid, BdsLoadingPage, BdsTypo } from 'blip-ds/dist/blip-ds-react';
import { EMAIL_TEAM, SUBJECT_CONNECTION_CHOICE } from 'constants/MailCredentials';
import { CONNECTION_CHOICE } from 'constants/MetaActivation';
import { SUCCESSFUL_ACTIVATION_CHOICE_ROUTE } from 'constants/Routes';
import { Analytics, Logger } from 'infra/adapters';
import { PlanTypeEnum } from 'objects/types/BlipGoApi';
import { useAuth } from 'oidc-react';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useContractInfo } from 'redux/slices/ContractInfoSlice/contractInfoSlice';
import { useProject } from 'redux/slices/ProjectSlice/projectSlice';
import { updateActivationChoice } from 'services/BlipGoApiService';
import { formatUpgradeWAMail, sendEmailTeam } from 'services/MailService';
import { createToastError, createToastSuccess } from 'services/Toats';
import { formatLogMessage } from 'utils/LogMessage';
import { ConnectionChoiceCard } from './components/ConnectionChoiceCard/ConnectionChoiceCard';

export const ConnectionChoice = () => {
  const userData = useAuth().userData;
  const className = 'ConnectionChoice';
  const [isLoading, setIsLoading] = useState(false);
  const [showContent, setShowContent] = useState(false);
  const { cnpj, companyName, companyWebsite, agentName, agentPosition, billingEmail, agentEmail } =
    useSelector(useContractInfo);
  const location = useLocation();
  const navigate = useNavigate();
  const { planType } = useSelector(useProject);

  useEffect(() => {
    if (location?.state) {
      const { activation } = location.state;
      if (activation !== CONNECTION_CHOICE.pending.key) {
        goToSuccessfullChoicePage(activation);
      }
    }
    setShowContent(true);
  }, []);

  const goToSuccessfullChoicePage = (activation: string) => {
    navigate(SUCCESSFUL_ACTIVATION_CHOICE_ROUTE, {
      state: { activation },
    });
  };

  const sendContractEmail = async (connectionChoice: string) => {
    const methodName = 'sendContractEmail';
    const tos = { toName: 'Experience Cluster', toMailAddress: EMAIL_TEAM };
    const userName = (userData?.profile?.FullName as string) || agentName.value;
    const html = formatUpgradeWAMail(
      agentName.value,
      agentEmail.value ?? '',
      billingEmail.value,
      companyName.value,
      cnpj?.value,
      companyWebsite.value,
      planType === PlanTypeEnum.Pro ? 'PRO' : 'Basic',
      agentPosition.value,
      connectionChoice,
    );
    try {
      await sendEmailTeam(userName, tos, SUBJECT_CONNECTION_CHOICE, html, true);
      Logger.Info(`activation email sent to ${userData?.profile?.email ?? ''}`, { methodName, className });
    } catch (ex) {
      Logger.Error(formatLogMessage(ex, `Failed to send email from ${userData?.profile?.email ?? ''}.`), {
        methodName,
        className,
      });
    }
  };

  const handleChoice = async (choice: string, tracking: string) => {
    const methodName = 'handleChoice';
    setIsLoading(true);
    const toastMessage =
      choice === CONNECTION_CHOICE.assisted.key
        ? CONNECTION_CHOICE.assisted.successToast
        : CONNECTION_CHOICE.self.successToast;
    try {
      await sendContractEmail(choice);
      await updateActivationChoice(choice, userData?.profile?.email as string);
      createToastSuccess('Solicitação enviada!', toastMessage);
      Analytics.Track(tracking);
      goToSuccessfullChoicePage(choice);
    } catch (ex) {
      createToastError('Erro na solicitação!', 'Tente novamente em alguns minutos.');
    } finally {
      setIsLoading(false);
    }

    Logger.Info(`user ${agentName.value} chose their meta connection option (${choice})`, { methodName, className });
  };

  return (
    <>
      {showContent && (
        <BdsGrid direction="column" lg="6" md="8" padding="t-6" style={{ textAlign: 'center', margin: 'auto' }}>
          {isLoading && <BdsLoadingPage />}
          <BdsTypo variant="fs-32" bold="bold">
            Escolha como conectar seu WhatsApp Blip Go!
          </BdsTypo>
          <BdsTypo variant="fs-16">
            Parabéns por chegar até aqui! Você está prestes a finalizar seu processo de ativação do WhatsApp. Por favor,
            escolha uma das <b>opções abaixo:👇</b>
          </BdsTypo>
          <BdsGrid gap="2" margin="t-2">
            <ConnectionChoiceCard
              icon={CONNECTION_CHOICE.self.icon}
              title={CONNECTION_CHOICE.self.title}
              description={CONNECTION_CHOICE.self.description}
              buttonLabel={CONNECTION_CHOICE.self.buttonLabel}
              choiceKey={CONNECTION_CHOICE.self.key}
              tracking={CONNECTION_CHOICE.self.tracking}
              buttonId={CONNECTION_CHOICE.self.buttonId}
              chooseEvent={handleChoice}
            />
            <ConnectionChoiceCard
              icon={CONNECTION_CHOICE.assisted.icon}
              title={CONNECTION_CHOICE.assisted.title}
              description={CONNECTION_CHOICE.assisted.description}
              buttonLabel={CONNECTION_CHOICE.assisted.buttonLabel}
              choiceKey={CONNECTION_CHOICE.assisted.key}
              tracking={CONNECTION_CHOICE.assisted.tracking}
              buttonId={CONNECTION_CHOICE.assisted.buttonId}
              chooseEvent={handleChoice}
            />
          </BdsGrid>
        </BdsGrid>
      )}
    </>
  );
};
