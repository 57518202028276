import {
  BdsAlert,
  BdsAlertActions,
  BdsAlertBody,
  BdsAlertHeader,
  BdsButton,
  BdsGrid,
  BdsStep,
  BdsStepper,
} from 'blip-ds/dist/blip-ds-react';
import LayoutContainer from 'components/LayoutContainer/LayoutContainer';
import MainLayout from 'components/MainLayout/MainLayout';
import { Analytics } from 'infra/adapters';
import { AnswerDirectingOptions, AudienceAndVariables, Steps } from 'objects/types/ActiveMessages';
import { Template } from 'objects/types/MessageTemplate';
import { useState } from 'react';
import { ActiveMessagesProViewAnswersDirecting } from '../ActiveMessagesProViewAnswersDirecting/ActiveMessagesProViewAnswersDirecting';
import { ActiveMessagesProViewMessagePreview } from '../ActiveMessagesProViewMessagePreview/ActiveMessagesProViewMessagePreview';
import { ActiveMessagesProViewSelectTemplate } from '../ActiveMessagesProViewSelectTemplate/ActiveMessagesProViewSelectTemplate';
import { ActiveMessagesProViewSendConfirmation } from '../ActiveMessagesProViewSendConfirmation/ActiveMessagesProViewSendConfirmation';

export const ActiveMessagesProView = () => {
  const [currentStep, setCurrentStep] = useState<Steps>(Steps.SelectTemplate);
  const [selectedTemplate, setSelectedTemplate] = useState<Template>();
  const [openCancelAlert, setOpenCancelAlert] = useState(false);
  const [answerDirecting, setAnswerDirecting] = useState<AnswerDirectingOptions>(AnswerDirectingOptions.Human);
  const [audience, setAudience] = useState<AudienceAndVariables[]>();

  const handleTemplateClick = (template: Template) => {
    setSelectedTemplate(template);
    Analytics.Track(Analytics.events.ACTIVE_MESSAGES_SELECTED_MODEL);
    setCurrentStep(Steps.MessagePreview);
  };

  const handleMessagePreviewNext = (audience: AudienceAndVariables[]) => {
    setAudience(audience);
    Analytics.Track(Analytics.events.ACTIVE_MESSAGES_MESSAGE_NEXT, { stage: 'preview' });
    setCurrentStep(Steps.AnswersDirecting);
  };

  const handleMessagePreviewBack = () => {
    Analytics.Track(Analytics.events.ACTIVE_MESSAGES_MESSAGE_BACK, { stage: 'preview' });
    setCurrentStep(Steps.SelectTemplate);
  };

  const handleAnswersDirectingNext = () => {
    Analytics.Track(Analytics.events.ACTIVE_MESSAGES_MESSAGE_RESPONSE_TARGETING, {
      targetIdentifier: answerDirecting === AnswerDirectingOptions.Human ? 'attHuman' : 'startFlow',
    });
    Analytics.Track(Analytics.events.ACTIVE_MESSAGES_MESSAGE_NEXT, { stage: 'targeting' });
    setCurrentStep(Steps.ConfirmSendMessage);
  };

  const handleAnswersDirectingBack = () => {
    Analytics.Track(Analytics.events.ACTIVE_MESSAGES_MESSAGE_BACK, { stage: 'targeting' });
    setCurrentStep(Steps.MessagePreview);
  };

  const showCancelConfirmation = () => {
    setOpenCancelAlert(true);
  };

  const handleCancel = () => {
    Analytics.Track(Analytics.events.ACTIVE_MESSAGES_MESSAGE_CANCEL_MODAL);
    Analytics.Track(Analytics.events.ACTIVE_MESSAGES_MESSAGE_CANCEL, { stage: getCurrentStage() });
    setOpenCancelAlert(false);
    setSelectedTemplate(undefined);
    setCurrentStep(Steps.SelectTemplate);
  };

  const hideCancelConfirmation = () => {
    Analytics.Track(Analytics.events.ACTIVE_MESSAGES_MESSAGE_CANCEL, { stage: 'modalCancel' });
    setOpenCancelAlert(false);
  };

  const getCurrentStage = () => {
    switch (currentStep) {
      case Steps.AnswersDirecting:
        return 'targeting';
      case Steps.ConfirmSendMessage:
        return 'summary';
    }
  };

  const handleAnswerDirectingOptionSelect = (selected: AnswerDirectingOptions) => {
    setAnswerDirecting(selected);
  };

  const handleSendConfirmationBack = () => {
    Analytics.Track(Analytics.events.ACTIVE_MESSAGES_MESSAGE_BACK, { stage: 'send_message' });
    setCurrentStep(Steps.AnswersDirecting);
  };

  const handleSendConfirmationEdit = () => {
    Analytics.Track(Analytics.events.ACTIVE_MESSAGES_MESSAGE_EDIT);
    setCurrentStep(Steps.MessagePreview);
  };

  const handleResetSteps = () => {
    setSelectedTemplate(undefined);
    setCurrentStep(Steps.SelectTemplate);
  };

  return (
    <MainLayout>
      <LayoutContainer>
        <BdsGrid direction="column" gap="2" padding="y-4" margin="x-12" height="calc(100vh - 4rem)">
          <BdsStepper>
            <BdsStep active={currentStep === Steps.SelectTemplate} data-testid="select-template">
              Selecionar mensagem
            </BdsStep>
            <BdsStep
              active={currentStep === Steps.MessagePreview || currentStep === Steps.AnswersDirecting}
              data-testid="message-preview"
            >
              Configurar disparo
            </BdsStep>
            <BdsStep active={currentStep === Steps.ConfirmSendMessage} data-testid="confirm-send-message">
              Confirmar envio
            </BdsStep>
          </BdsStepper>
          {currentStep === Steps.SelectTemplate && (
            <ActiveMessagesProViewSelectTemplate handleTemplateClick={template => handleTemplateClick(template)} />
          )}
          {currentStep === Steps.MessagePreview && (
            <ActiveMessagesProViewMessagePreview
              template={selectedTemplate}
              handleMessagePreviewNext={handleMessagePreviewNext}
              handleMessagePreviewBack={handleMessagePreviewBack}
            />
          )}
          {currentStep === Steps.AnswersDirecting && (
            <ActiveMessagesProViewAnswersDirecting
              answerDirecting={answerDirecting}
              handleAnswersDirectingNext={handleAnswersDirectingNext}
              handleAnswersDirectingBack={handleAnswersDirectingBack}
              showCancelConfirmation={showCancelConfirmation}
              handleAnswerDirectingOptionSelect={handleAnswerDirectingOptionSelect}
            />
          )}
          {currentStep === Steps.ConfirmSendMessage && (
            <ActiveMessagesProViewSendConfirmation
              template={selectedTemplate}
              audience={audience}
              answerDirecting={answerDirecting}
              showCancelConfirmation={showCancelConfirmation}
              handleSendConfirmationBack={handleSendConfirmationBack}
              handleSendConfirmationEdit={handleSendConfirmationEdit}
              handleResetSteps={handleResetSteps}
            />
          )}
        </BdsGrid>
        <BdsAlert open={openCancelAlert}>
          <BdsAlertHeader variant="warning" icon="warning">
            Deseja cancelar o envio?
          </BdsAlertHeader>
          <BdsAlertBody>
            Ao <b>confirmar</b>, você perderá todo o progresso e precisará preencher novamente as informações.
          </BdsAlertBody>
          <BdsAlertActions>
            <BdsButton variant="secondary" onBdsClick={hideCancelConfirmation}>
              Voltar
            </BdsButton>
            <BdsButton variant="secondary" onBdsClick={handleCancel} data-testid="confirm-cancel-button">
              Confirmar
            </BdsButton>
          </BdsAlertActions>
        </BdsAlert>
      </LayoutContainer>
    </MainLayout>
  );
};
