import { LARGE_MOBILE_SCREEN } from 'constants/MediaQueryResolutions';
import styled from 'styled-components';

export const Container = styled.div`
  .number-preparation-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100vh - 90px);
    margin-top: 10px;
    padding: 24px;

    @media screen and (max-width: ${LARGE_MOBILE_SCREEN}) {
      padding: 16px;
      height: calc(100vh - 110px);
    }
  }
`;
