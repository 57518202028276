import { BdsButton, BdsGrid, BdsIllustration, BdsPaper, BdsTypo } from 'blip-ds/dist/blip-ds-react';
import { Logger } from 'infra/adapters';
import { WabaActivation } from 'pages/ExperienceCluster/subPages/WabaActivation/WabaActivation';
import { WhatsappActivationFooter } from 'pages/ExperienceCluster/subPages/WhatsappActivationFooter/WhatsappActivationFooter';
import { useEffect } from 'react';
import { useMetaConnection } from './hooks/useMetaConnection';
import './style.scss';
import { MainContainer, MetaLink } from './styles';

const className = 'MetaConnection';

export const MetaConnection = () => {
  const {
    handleLeanMoreLink,
    handleRegisterBtn,
    retryOpenEmbedded,
    handleReportProblemBtn,
    metaUserToken,
    readyToConnectToWaba,
    showRetryPage,
    initHook,
  } = useMetaConnection();
  const methodName = 'MetaConnection';

  useEffect(() => {
    initHook();
    Logger.Info('Meta Connection page loaded', { methodName, className });
  }, []);

  const RenderFirstMetaConnection = (
    <BdsGrid className="meta-connection" direction="column" gap="4" margin="t-1">
      <BdsTypo variant="fs-24" bold="bold" margin={false}>
        Vinculação de conta com a Meta
      </BdsTypo>
      {showRetryPage ? (
        <BdsGrid direction="column" gap="8" padding="none">
          <BdsTypo variant="fs-16" margin={false}>
            <span>Se o facebook não abrir, </span>
            <MetaLink data-testid={'click-here'} onClick={() => retryOpenEmbedded()}>
              clique aqui
            </MetaLink>
          </BdsTypo>
          <WhatsappActivationFooter linkPressed={() => handleReportProblemBtn()} linkTitle="Preciso de ajuda" />
        </BdsGrid>
      ) : (
        <>
          <BdsPaper className="container-card">
            <BdsGrid direction="row" justifyContent="space-between">
              <BdsIllustration className="whatsapp-image" type="logo-integration" name="blip-whatsapp" />
              <BdsGrid direction="column" gap="2" padding="none">
                <BdsTypo variant="fs-20" bold="bold" margin={false}>
                  Conecte ao Gerenciador de Negócios
                </BdsTypo>
                <BdsTypo className="text" variant="fs-16" margin={false}>
                  Para utilizar a API, você precisa cadastrar sua empresa no Gerenciador de Negócios da Meta. É lá que
                  você administra o número de WhatsApp e envia informações da sua empresa.
                  <br />
                  <b>Se já tiver uma conta, use o login do Facebook para acessar.</b>
                </BdsTypo>
                <BdsButton
                  className="access-button"
                  size="standard"
                  variant="primary"
                  icon="external-file"
                  onBdsClick={() => handleRegisterBtn()}
                  block={true}
                  data-testid="access-button"
                >
                  Acessar agora
                </BdsButton>
              </BdsGrid>
            </BdsGrid>
          </BdsPaper>
          <BdsGrid direction="row" justifyContent="space-between" alignItems="center">
            <BdsTypo variant="fs-14" margin={false}>
              <MetaLink onClick={() => handleLeanMoreLink()} data-testid="learn-more">
                Saiba mais
              </MetaLink>
              <span> sobre o Gerenciador de Negócios</span>
            </BdsTypo>
            <WhatsappActivationFooter linkPressed={() => handleReportProblemBtn()} linkTitle="Preciso de ajuda" />
          </BdsGrid>
        </>
      )}
    </BdsGrid>
  );

  return (
    <MainContainer>
      {readyToConnectToWaba ? <WabaActivation metaUserToken={metaUserToken} /> : RenderFirstMetaConnection}
    </MainContainer>
  );
};
