import robotPhone from 'assets/RobotPhone.svg';
import { BdsButton, BdsGrid, BdsModalAction, BdsTypo } from 'blip-ds/dist/blip-ds-react';
import { PRO_PLAN_INFO } from 'constants/WppModalInfo';
import parse from 'html-react-parser';
import { ModalSubItem } from '../ModalSubItem/ModalSubItem';

interface IProModalContent {
  activeMessageOrigin?: boolean;
  handleCloseModal: () => void;
  handlePlanDirection: () => void;
}

export const ProModalContent = ({ activeMessageOrigin, handleCloseModal, handlePlanDirection }: IProModalContent) => {
  return (
    <div>
      <BdsGrid gap="4" margin="t-4" style={{ width: '43.75rem' }}>
        <img src={robotPhone} alt="" />
        <BdsGrid direction="column" gap="2">
          <BdsTypo variant="fs-20" margin={false} bold="bold">
            {activeMessageOrigin ? PRO_PLAN_INFO.activeMessage.title : PRO_PLAN_INFO.general.title}
          </BdsTypo>
          <BdsTypo variant="fs-14">
            {activeMessageOrigin ? parse(PRO_PLAN_INFO.activeMessage.intro) : parse(PRO_PLAN_INFO.general.intro)} Não
            perca tempo, faça o upgrade hoje mesmo!
          </BdsTypo>
          <ModalSubItem itemLabel="Até 450 conversas/mês" />
          <ModalSubItem itemLabel="Campanha de marketing via WhatsApp" />
          <ModalSubItem itemLabel="7 atendentes em até 5 equipes" />
          <BdsTypo bold="bold" variant="fs-14">
            E muito mais, por apenas R$ 399,00/mês.
          </BdsTypo>
        </BdsGrid>
      </BdsGrid>
      <BdsModalAction>
        <BdsGrid gap="2" justifyContent="flex-end" padding="t-2" style={{ width: '100%' }}>
          <BdsButton variant="secondary" onBdsClick={handleCloseModal} data-testid="plan-modal-close-btn">
            Cancelar
          </BdsButton>
          <BdsButton arrow={true} onBdsClick={handlePlanDirection} data-testid="plan-modal-btn">
            Conhecer o Plano PRO
          </BdsButton>
        </BdsGrid>
      </BdsModalAction>
    </div>
  );
};
