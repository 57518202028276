import { BdsButton, BdsGrid, BdsIllustration, BdsModal, BdsTypo } from 'blip-ds/dist/blip-ds-react';
import LayoutContainer from 'components/LayoutContainer/LayoutContainer';
import MainLayout from 'components/MainLayout/MainLayout';
import { PACK_MANAGER_URL, PACK_MANAGER_URL_OLD } from 'configurations/Environment';
import ExternalUrls from 'constants/ExternalUrls';
import { HOME_ROUTE } from 'constants/Routes';
import { useHashPrevention } from 'hooks/useHashPrevention';
import { Analytics } from 'infra/adapters';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useProject } from 'redux/slices/ProjectSlice/projectSlice';
import ActivateChannel from './components/ActivateChannel';
import CustomizeChatbot from './components/CustomizeChatbot';
import { BottomContainer, BoxImageBanner, BoxTextBanner, LinkNewUrl, LinkPhoneNumber } from './styles';

const Home: React.FC = () => {
  useHashPrevention(HOME_ROUTE);
  const { whatsAppActive, instagramActive, messengerActive } = useSelector(useProject);
  const [hasActiveChannel, setHasActiveChannel] = useState<boolean>(false);

  const modalRef = useRef<null | HTMLBdsModalElement>(null);

  useEffect(() => {
    const currentUrl = window.location.href;
    const oldUrl = PACK_MANAGER_URL_OLD + 'home';
    if (currentUrl === oldUrl) {
      if (modalRef?.current) {
        modalRef.current.toggle?.();
      }
      return;
    }

    Analytics.Track(Analytics.events.PAGE_HOME_ACCESS);
    if (whatsAppActive || instagramActive || messengerActive) {
      setHasActiveChannel(true);
    }
  }, [whatsAppActive, instagramActive, messengerActive]);

  return (
    <MainLayout>
      <LayoutContainer>
        <BdsModal
          ref={modalRef}
          size="dynamic"
          closeButton={false}
          onBdsModalChanged={ev => {
            if (ev.detail.modalStatus === 'closed') {
              Analytics.Track(Analytics.events.PAGE_HOME_CLICK_CLOSE_OLD_URL);
              window.open(PACK_MANAGER_URL, '_self', 'noopener');
            }
          }}
        >
          <BdsGrid direction="column">
            <BdsGrid direction="row" justifyContent="center" alignItems="center" gap="5">
              <BoxImageBanner>
                <BdsIllustration type="default" name="settings-1" />
              </BoxImageBanner>
              <BoxTextBanner>
                <BdsTypo variant="fs-20" bold="bold" margin={false}>
                  Mudança de URL em andamento!
                </BdsTypo>
                <BdsTypo variant="fs-14" bold="regular" margin={false}>
                  Estamos atualizando nossa URL para melhorar sua experiência. Acesse a nova URL:{' '}
                  <LinkNewUrl to={PACK_MANAGER_URL}>{PACK_MANAGER_URL}</LinkNewUrl>.
                </BdsTypo>
                <BdsTypo variant="fs-14" bold="regular" margin={false}>
                  A URL antiga continuará disponível até <b>31/01/2025,</b> se precisar de ajuda, entre em contato pelo
                  suporte:{' '}
                  <LinkPhoneNumber to="https://wa.me/5531992670115?text=Ol%C3%A1!%20Preciso%20de%20ajuda%20com%20a%20nova%20URL%20do%20Blip%20Go!">
                    +55 31 99267-0115.
                  </LinkPhoneNumber>
                </BdsTypo>
              </BoxTextBanner>
            </BdsGrid>
            <BdsGrid padding="r-4" justifyContent="flex-end">
              <BdsButton
                onBdsClick={() => {
                  Analytics.Track(Analytics.events.PAGE_HOME_CLICK_ACCESS_NEW_URL);
                  window.open(PACK_MANAGER_URL, '_self', 'noopener');
                }}
              >
                Acessar nova URL
              </BdsButton>
            </BdsGrid>
          </BdsGrid>
        </BdsModal>
        {hasActiveChannel ? <CustomizeChatbot /> : <ActivateChannel />}
        <BottomContainer>
          <BdsTypo variant="fs-12">
            © {new Date().getFullYear()} Blip Go! - Powered by Blip | Todos os direitos reservados |
            <a href={ExternalUrls.termsOfUseBlipGo} target="_blank" rel="noreferrer">
              &nbsp;Termos de Uso
            </a>
          </BdsTypo>
        </BottomContainer>
      </LayoutContainer>
    </MainLayout>
  );
};

export default Home;
