import { BdsButton, BdsCheckbox, BdsGrid, BdsLoadingPage } from 'blip-ds/dist/blip-ds-react';
import {
  docusignAccountId,
  docusignClickwrapIdPlanBasic,
  docusignClickwrapIdPlanPro,
  docusignEnvironment,
} from 'configurations/Environment';
import { EMAIL_TEAM, SUBJECT_CONTRACT } from 'constants/MailCredentials';
import {
  INFORMATION_REVIEW_CONTRACT_INFO,
  INFORMATION_REVIEW_PAY,
  INFORMATION_REVIEW_PLAN,
} from 'constants/MetaActivation';
import { CONNECTION_CHOICE_ROUTE, HOME_ROUTE } from 'constants/Routes';
import { Analytics, Logger } from 'infra/adapters';
import moment from 'moment';
import { PlanTypeEnum } from 'objects/types/BlipGoApi';
import { useAuth } from 'oidc-react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { updateSignedContract, useContractInfo } from 'redux/slices/ContractInfoSlice/contractInfoSlice';
import { useInstallation } from 'redux/slices/InstallationSlice/installationSlice';
import { updatePlanType, useProject } from 'redux/slices/ProjectSlice/projectSlice';
import { saveSignerInfo, saveUserInfo } from 'services/BlipGoApiService';
import { formatUpgradeWAMail, sendEmailTeam } from 'services/MailService';
import { createToastError } from 'services/Toats';
import { formatLogMessage } from 'utils/LogMessage';
import { InfoCard } from './components/InfoCard/InfoCard';
import { ReviewInfoContainer, ReviewInfoContainerV2 } from './styles';
import { InfoCardV2 } from './components/InfoCardV2/InfoCardV2';

interface IProps {
  previousPageEvent: () => void;
  signaturePlanType: PlanTypeEnum;
}

export const ReviewPlan = ({ previousPageEvent, signaturePlanType }: IProps) => {
  const className = 'ReviewPlan';
  const dispatch = useDispatch();
  const userData = useAuth().userData;
  const { installationId } = useSelector(useInstallation);
  const { planType, whatsAppActive } = useSelector(useProject);
  const { cnpj, billingEmail, agentEmail, companyName, companyWebsite, agentName, agentPosition } =
    useSelector(useContractInfo);
  const [isLoading, setIsLoading] = useState(false);
  const [userAgreement, setUserAgreement] = useState(false);
  const { ownerIdentity } = useSelector(useInstallation);
  const navigate = useNavigate();
  const subitemsContract = [
    `${INFORMATION_REVIEW_CONTRACT_INFO.companyLabel}: ${companyName.value}`,
    `${INFORMATION_REVIEW_CONTRACT_INFO.cnpjLabel}: ${cnpj.value}`,
    `${INFORMATION_REVIEW_CONTRACT_INFO.siteLabel}: ${companyWebsite.value}`,
    `${INFORMATION_REVIEW_CONTRACT_INFO.agentName}: ${agentName.value}`,
    `${INFORMATION_REVIEW_CONTRACT_INFO.agentEmail}: ${billingEmail.value}`,
    `${INFORMATION_REVIEW_CONTRACT_INFO.agentPos}: ${agentPosition.value}`,
  ];

  const handlePreviousBtnClick = () => {
    Analytics.Track(Analytics.events.CONTRACT_PLAN_RETURN, {
      screenName: 'Resumo',
    });
    previousPageEvent();
  };

  const openClickwrapModal = (accountId: string, clickwrapId: string) => {
    const day = moment().format('DD');
    const month = moment().format('MMMM');
    const year = moment().format('YYYY');
    (window as any)?.docuSignClick.Clickwrap.render(
      {
        environment: docusignEnvironment,
        accountId: accountId,
        clickwrapId: clickwrapId,
        clientUserId: cnpj.value,
        documentData: {
          fullName: agentName?.value,
          email: agentEmail?.value,
          company: `${companyName?.value}. CNPJ: ${cnpj?.value}`,
          title: agentPosition?.value,
          date: `${day} de ${month} de ${year}`,
        },
        onError: () => setIsLoading(false),
        onMustAgree: () => setIsLoading(false),
        onAgreed: () => {
          goToConclusionStep();
        },
        onDeclined: () => {
          Analytics.Track(Analytics.events.DOCUSIGN_CLICKWRAP_DECLINE);
          setIsLoading(false);
        },
      },
      '#ec-ds-clickwrap',
    );
  };

  const handleContract = async () => {
    const methodName = 'handleContract';

    setIsLoading(true);
    try {
      await saveSignerInfo(
        agentEmail?.value,
        billingEmail?.value,
        agentPosition?.value,
        agentName?.value,
        cnpj?.value,
        companyName?.value,
        installationId,
        signaturePlanType,
      );
      Analytics.Track(Analytics.events.CONTRACT_COMPANY_COMPLETE);
      Logger.Info('Docusign user info saved on db', { methodName, className });
      if (signaturePlanType === PlanTypeEnum.Pro) {
        openClickwrapModal(docusignAccountId, docusignClickwrapIdPlanPro);
      }
      if (signaturePlanType === PlanTypeEnum.Basic) {
        openClickwrapModal(docusignAccountId, docusignClickwrapIdPlanBasic);
      }
    } catch (ex) {
      Logger.Error(formatLogMessage(ex, `Failed to save user data ${billingEmail?.value}.`), {
        methodName,
        className,
      });
      createToastError('Ocorreu um erro', 'Desculpe, houve algum erro interno. Por favor, tente novamente mais tarde.');
      setIsLoading(false);
    }
  };

  const sendEmailContract = async () => {
    const methodName = 'sendEmailContract';
    const userName = userData?.profile?.FullName as string;
    const tos = { toName: 'Experience Cluster', toMailAddress: EMAIL_TEAM };
    const html = formatUpgradeWAMail(
      userName,
      agentEmail.value,
      billingEmail.value,
      companyName.value || '',
      cnpj?.value,
      companyWebsite.value,
      signaturePlanType === PlanTypeEnum.Pro ? 'PRO' : 'Basic',
      agentPosition.value,
    );
    try {
      await sendEmailTeam(userName, tos, SUBJECT_CONTRACT, html, true);
      Logger.Info('contract e-mail sent to EC list', {
        methodName,
        className,
      });
    } catch (ex) {
      Logger.Error(
        formatLogMessage(ex, `Failed to send email from user ${userName} and email ${userData?.profile?.email}.`),
        {
          methodName,
          className,
        },
      );
    }
  };

  const goToConclusionStep = async () => {
    const methodName = 'goToConclusionStep';
    setIsLoading(false);
    try {
      Logger.Info('saving user approval info on experience cluster db', { methodName, className });
      if (planType === PlanTypeEnum.Free) {
        await saveUserInfo(ownerIdentity);
      }
      Logger.Info('sending email about a new contract to EC email list', { methodName, className });
      await sendEmailContract();
      Analytics.Track(Analytics.events.DOCUSIGN_CLICKWRAP_AGREE);
      dispatch(updateSignedContract(true));

      if (planType === PlanTypeEnum.Basic && whatsAppActive) {
        navigate(HOME_ROUTE);
      } else {
        navigate(CONNECTION_CHOICE_ROUTE);
      }

      Logger.Info('redirecting user to connection choice page', { methodName, className });

      dispatch(updatePlanType(signaturePlanType));
    } catch (ex) {
      Logger.Error(formatLogMessage(ex, `failure to complete user agreement ${userData?.profile?.FullName}.`), {
        methodName,
        className,
      });
      createToastError('Falha ao salvar informações.', 'Por favor, tente novamente em outro navegador.');
    }
  };

  const handleAgreementCheck = (checked: boolean) => {
    setUserAgreement(checked);
    Analytics.Track(Analytics.events.CONTRACT_AGREEMENT, { checked });
  };

  return signaturePlanType === PlanTypeEnum.Pro ? (
    <ReviewInfoContainerV2>
      {isLoading && <BdsLoadingPage />}
      <BdsGrid direction="row" gap="2" padding="none">
        <BdsGrid direction="column" padding="none" gap="3">
          <InfoCardV2
            title={INFORMATION_REVIEW_CONTRACT_INFO?.title}
            subitems={subitemsContract}
            cardColor={INFORMATION_REVIEW_CONTRACT_INFO?.cardColor}
            subitemFontWeigth="regular"
            startCollapsed={false}
          />

          <InfoCardV2
            title={INFORMATION_REVIEW_PLAN.planPro.title}
            subitems={INFORMATION_REVIEW_PLAN.planPro.subitems}
            cardColor={INFORMATION_REVIEW_PLAN.planPro.cardColor}
            subitemFontWeigth="regular"
            startCollapsed={true}
          />
        </BdsGrid>

        <BdsGrid direction="column" padding="none" gap="3">
          <InfoCard
            title={INFORMATION_REVIEW_PAY?.title}
            subtitle1={INFORMATION_REVIEW_PAY?.subtitle1}
            subtitle2={INFORMATION_REVIEW_PAY?.subtitle2}
            subtitle3={INFORMATION_REVIEW_PAY?.subtitle3}
            subtitle1And2Color="#1E6BF1"
            subitems={INFORMATION_REVIEW_PAY?.subitems}
            observations={INFORMATION_REVIEW_PAY?.observations}
            cardColor={INFORMATION_REVIEW_PAY?.cardColor}
            subitemFontWeigth="regular"
          />

          <BdsCheckbox
            checked={userAgreement}
            label="Estou ciente e concordo com o modelo de cobrança descrito em Forma de pagamento."
            name=""
            refer=""
            onBdsChange={ev => handleAgreementCheck(ev.detail.checked)}
          />

          <BdsGrid margin="b-1" justifyContent="space-between">
            <BdsButton
              icon="arrow-left"
              variant="ghost"
              onClick={handlePreviousBtnClick}
              data-testid="company-info-previous-btn"
            >
              Anterior
            </BdsButton>
            <BdsButton
              icon="checkball"
              disabled={!userAgreement}
              onBdsClick={handleContract}
              data-testid="company-info-next-btn"
            >
              Ir para o contrato
            </BdsButton>
          </BdsGrid>
        </BdsGrid>
      </BdsGrid>
    </ReviewInfoContainerV2>
  ) : (
    <ReviewInfoContainer>
      {isLoading && <BdsLoadingPage />}

      <InfoCard
        title={INFORMATION_REVIEW_CONTRACT_INFO?.title}
        subitems={subitemsContract}
        cardColor={INFORMATION_REVIEW_CONTRACT_INFO?.cardColor}
        subitemFontWeigth="regular"
      />

      <InfoCard
        title={INFORMATION_REVIEW_PLAN.planBasic.title}
        subitems={INFORMATION_REVIEW_PLAN.planBasic.subitems}
        cardColor={INFORMATION_REVIEW_PLAN.planBasic.cardColor}
        subitemFontWeigth="regular"
      />

      <BdsCheckbox
        checked={userAgreement}
        label="Estou ciente de que o plano possui 50 conversas mensais grátis e que, ao exceder, podem haver custos extras, que serão comunicados previamente."
        name=""
        refer=""
        onBdsChange={ev => handleAgreementCheck(ev.detail.checked)}
      />

      <BdsGrid margin="b-1" justifyContent="space-between">
        <BdsButton
          icon="arrow-left"
          variant="ghost"
          onClick={handlePreviousBtnClick}
          data-testid="company-info-previous-btn"
        >
          Anterior
        </BdsButton>
        <BdsButton
          icon="checkball"
          disabled={isLoading}
          bdsLoading={isLoading}
          onBdsClick={handleContract}
          data-testid="company-info-next-btn"
        >
          Ir para o contrato
        </BdsButton>
      </BdsGrid>
    </ReviewInfoContainer>
  );
};
