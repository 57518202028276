export const PLAN_UPGRADE_INFO = [
  {
    label: 'Canal WhatsApp',
    freeIncluded: true,
    proIncluded: true,
    showWhatsappLogo: true,
    additionalInfoFree: '',
    additionalInfoPro: '',
    tooltipText: 'Somos parceiros oficial Meta, utilize o canal sem riscos!',
  },
  {
    label: 'Campanhas de marketing via WhatsApp',
    freeIncluded: false,
    proIncluded: true,
    showWhatsappLogo: false,
    additionalInfoFree: '',
    additionalInfoPro: '',
    tooltipText:
      'Aumente a visibilidade da sua marca, promova produtos e serviços de forma personalizada e conquiste seus clientes!',
  },
  {
    label: 'Celulares com o WhatsApp Blip Go!',
    freeIncluded: true,
    proIncluded: true,
    showWhatsappLogo: false,
    additionalInfoFree: '4',
    additionalInfoPro: '7',
    tooltipText: 'Conecte o Blip Go ao WhatsApp e gerencie os dispositivos para facilitar o trabalho da sua equipe.',
  },
  {
    label: 'Indicadores de atendimento e relatórios completos',
    freeIncluded: false,
    proIncluded: true,
    showWhatsappLogo: false,
    additionalInfoFree: '',
    additionalInfoPro: '',
    tooltipText: '',
  },
  {
    label: 'Atendentes',
    freeIncluded: true,
    proIncluded: true,
    showWhatsappLogo: false,
    additionalInfoFree: '4',
    additionalInfoPro: '7',
    tooltipText: 'Adicione mais atendentes e amplie as possibilidades de atendimento da sua equipe.',
  },
  {
    label: 'Equipes de atendimento',
    freeIncluded: true,
    proIncluded: true,
    showWhatsappLogo: false,
    additionalInfoFree: '1',
    additionalInfoPro: '5',
    tooltipText: '',
  },
  {
    label: 'Conversas',
    freeIncluded: true,
    proIncluded: true,
    showWhatsappLogo: false,
    additionalInfoFree: '50/mês',
    additionalInfoPro: '450/mês',
    tooltipText: 'As conversas são consideradas por cada usuário único e contabilizadas por mês ',
  },
  {
    label: 'Valor por conversa extra',
    freeIncluded: true,
    proIncluded: true,
    showWhatsappLogo: false,
    additionalInfoFree: 'R$ 2,00',
    additionalInfoPro: 'R$ 1,00',
    tooltipText: 'Continue interagindo com seus clientes, gerenciando conversas extras com uma cobrança adicional.',
  },
];

export const PRO_PLAN_INFO = {
  general: {
    intro:
      'Para ativar o <b>WhatsApp</b> e aproveitar todos os recursos disponíveis, é necessário fazer o upgrade para o <b>Blip Go! PRO</b>.',
    title: 'Aproveite recursos exclusivos com o Blip Go! PRO!',
  },

  activeMessage: {
    intro: 'Para aproveitar todos os recursos disponíveis, é necessário fazer o upgrade para o <b>Blip Go! PRO</b>. ',
    title: 'Aproveite recursos exclusivos com o Blip Go! PRO!',
  },
};
