import { LARGE_MOBILE_SCREEN } from 'constants/MediaQueryResolutions';
import styled from 'styled-components';

export const ReviewInfoContainer = styled.div`
  display: grid;
  gap: 1rem;
  width: 32.625rem;
  padding-bottom: 5rem;

  @media screen and (max-width: ${LARGE_MOBILE_SCREEN}) {
    width: auto;
    margin: 0.3125rem;
  }
`;

export const ReviewInfoContainerV2 = styled.div`
  display: grid;
  gap: 1rem;
  padding-bottom: 5rem;

  @media screen and (max-width: ${LARGE_MOBILE_SCREEN}) {
    width: auto;
    margin: 0.3125rem;
  }
`;
