import { BdsGrid, BdsIcon, BdsPaper, BdsTypo } from 'blip-ds/dist/blip-ds-react';
import { ReviewContractInfoObservations, ReviewContractInfoSubitems, ReviewInfoCardContainer } from './styles';
import { Bold } from 'blip-ds/dist/types/components/typo/typo';
import { useState } from 'react';

interface IInfoCardV2 {
  subitems: string[];
  title: string;
  subitemFontWeigth: Bold;
  observations?: string[];
  cardColor: string;
  startCollapsed: boolean;
}

export const InfoCardV2 = ({
  subitems,
  title,
  subitemFontWeigth,
  observations,
  cardColor,
  startCollapsed,
}: IInfoCardV2) => {
  const [isCollapsed, setIsCollapsed] = useState(startCollapsed);

  return (
    <BdsPaper elevation="static" style={{ backgroundColor: cardColor }} key={title}>
      <ReviewInfoCardContainer>
        <BdsGrid
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          onClick={() => setIsCollapsed(!isCollapsed)}
        >
          <BdsTypo variant="fs-20" bold="bold" margin={false}>
            {title}
          </BdsTypo>
          <BdsIcon theme="outline" name={isCollapsed ? 'arrow-up' : 'arrow-down'} size="x-large" />
        </BdsGrid>
        {isCollapsed && (
          <>
            <ReviewContractInfoSubitems>
              {subitems.map(subitem => {
                return (
                  <BdsTypo variant="fs-14" bold={subitemFontWeigth} key={subitem} data-testid="content-subitem">
                    {subitem}
                  </BdsTypo>
                );
              })}
            </ReviewContractInfoSubitems>
            {observations ? (
              <ReviewContractInfoObservations>
                {observations?.map(observation => {
                  return (
                    <BdsTypo variant="fs-12" italic key={observation} data-testid="observation-item">
                      {observation}
                    </BdsTypo>
                  );
                })}
              </ReviewContractInfoObservations>
            ) : null}
          </>
        )}
      </ReviewInfoCardContainer>
    </BdsPaper>
  );
};
