import {
  BdsButton,
  BdsGrid,
  BdsIcon,
  BdsInput,
  BdsSelect,
  BdsSelectOption,
  BdsTooltip,
  BdsTypo,
} from 'blip-ds/dist/blip-ds-react';
import { OfferBanner } from 'components/OfferBanner/OfferBanner';
import SubPageHeader from 'components/SubPageHeader/SubPageHeader';
import { ATTENDANT_MEMBER_ID } from 'configurations/Environment';
import { ANALYTICS } from 'constants/Analytics';
import { AttendantPermission } from 'constants/Permissions';
import { PROFILE } from 'constants/Profiles';
import { EMAIL_REGEX } from 'constants/Regex';
import { PAYWALL_ROUTE } from 'constants/Routes';
import { SubModuleNames } from 'constants/SubModuleNames';
import {
  TOAST_FAILURE_MESSAGE,
  TOAST_SUCCESS,
  TOAST_SUCCESS_MESSAGE_INVITE_MEMBER,
  TOAST_SUCCESS_MESSAGE_REMOVE_MEMBER,
} from 'constants/ToastMessages';
import { useBotPermissions } from 'hooks/useBotPermissions';
import { Analytics } from 'infra/adapters';
import { BotPermissions } from 'objects/types/BlipApi';
import { PlanTypeEnum } from 'objects/types/BlipGoApi';
import { MemberInfo } from 'objects/types/Profile';
import { TrackingProperties } from 'objects/types/TrackingProperties';
import { UserTenant } from 'objects/types/UserTenant';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useAccount } from 'redux/slices/AccountSlice/accountSlice';
import { useCustomerService } from 'redux/slices/CustomerServiceSlice/customerServiceSlice';
import { addAttendant, removeAttendant } from 'redux/slices/CustomerServiceSlice/thunkMiddleware';
import { useInstallation } from 'redux/slices/InstallationSlice/installationSlice';
import { addAdmin, addAgent, removeMember, useMembers } from 'redux/slices/MembersSlice/membersSlice';
import { useProject } from 'redux/slices/ProjectSlice/projectSlice';
import { AppDispatch } from 'redux/store';
import {
  AddUsersTenant,
  associateMember,
  createMember,
  disassociateMember,
  getMemberByEmail,
} from 'services/PackService';
import { createToastError, createToastSuccess } from 'services/Toats';
import { encodeAttendantBlipEmail, encodeToDeleteAttendantBlipEmail } from 'utils/BlipEncodeDecode';
import { GetBotAuthorization } from 'utils/Installation';
import ConfigurationsModuleTexts from 'utils/texts/ConfigurationsModuleTexts.json';
import ConfigurationsContainer from '../components/ConfigurationsContainer/ConfigurationsContainer';
import { MemberItem } from './components/MemberItem/MemberItem';

export const Members = () => {
  const MAX_ATTENDANTS_BASIC = 4;
  const DEFAULT_TYPE_PROFILE_INITIAL = '0';

  const { admins, agents } = useSelector(useMembers);
  const [email, setEmail] = useState('');
  const [typeProfile, setTypeProfile] = useState(DEFAULT_TYPE_PROFILE_INITIAL);
  const [emailError, setEmailError] = useState(false);
  const [enabledSubmmit, setEnabledSubmmit] = useState(false);
  const [isSubmitting, setIsSubmtting] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const { attendants } = useSelector(useCustomerService);
  const { desk, ownerIdentity, applicationId, tenant, installationId } = useSelector(useInstallation);
  const key = GetBotAuthorization(desk.shortName, desk.accessKey);
  const [showTooltip, setshowTooltip] = useState(attendants.length >= MAX_ATTENDANTS_BASIC);
  const { planType } = useSelector(useProject);
  const [botPermissions, setBotPermissions] = useState<BotPermissions>();
  const { handleAttendantPermissionChange, handleBotPermissions } = useBotPermissions();
  const { token, profile } = useSelector(useAccount);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchPermissions = async () => {
      const permissions = await handleBotPermissions();
      if (permissions) {
        setBotPermissions(permissions);
      }
    };
    fetchPermissions();
  }, []);

  useEffect(() => {
    if (planType !== PlanTypeEnum.Pro) {
      if (attendants.length >= MAX_ATTENDANTS_BASIC && typeProfile === ATTENDANT_MEMBER_ID) {
        setshowTooltip(true);
        setEnabledSubmmit(false);
      } else {
        setshowTooltip(false);
        setEnabledSubmmit(email.length > 0 && typeProfile !== DEFAULT_TYPE_PROFILE_INITIAL);
      }
    } else {
      setshowTooltip(false);
      setEnabledSubmmit(email.length > 0 && typeProfile !== DEFAULT_TYPE_PROFILE_INITIAL);
    }
  }, [email, typeProfile, attendants]);

  const handleAddMember = async () => {
    if (!validateEmail()) {
      setEmailError(true);
      return;
    }

    try {
      setIsSubmtting(true);
      if (typeProfile === PROFILE.agent.id) {
        await createAttendant();
      }

      await createMembers();

      Analytics.Track(ANALYTICS.INVITE_MEMBER, {
        userPermission: typeProfile === PROFILE.admin.id ? PROFILE.admin.name : PROFILE.agent.name,
        numAgents: attendants.length + 1,
      });

      createToastSuccess(TOAST_SUCCESS, TOAST_SUCCESS_MESSAGE_INVITE_MEMBER);
    } catch {
    } finally {
      clearFields();
      setIsSubmtting(false);
    }
  };

  const createMembers = async () => {
    const existMember = await getMemberByEmail(email, token);

    if (existMember) {
      await associateMember(existMember.members[0].id, installationId, token);
      const memberInfo: MemberInfo = {
        email: existMember.members[0].email,
        id: existMember.members[0].id,
        name: existMember.members[0].name,
        profileName: '',
      };
      updateMemberSlice(typeProfile, memberInfo);
    } else {
      const member = await createMember(email, installationId, typeProfile, token);
      if (member?.length) {
        updateMemberSlice(typeProfile, member[0]);
      }
    }
  };

  const updateMemberSlice = (typeProfile: string, memberInfo: MemberInfo) => {
    if (typeProfile == PROFILE.admin.id) {
      memberInfo.profileName = PROFILE.admin.name;
      dispatch(addAdmin(memberInfo));
    } else {
      memberInfo.profileName = PROFILE.agent.name;
      dispatch(addAgent(memberInfo));
    }
  };

  const addUsersTenant = async () => {
    try {
      const emails = [];
      attendants.forEach(item => {
        emails.push(item.email);
      });

      emails.push(email);

      const userTenant: UserTenant = {
        ownerEmail: ownerIdentity,
        packId: Number(applicationId),
        tenantId: tenant.id,
        usersEmails: emails,
      };
      await AddUsersTenant(userTenant, token);
    } catch (ex) {
      const error = ex as Error;
      return Promise.reject(error);
    }
  };

  const createAttendant = async () => {
    const trackingProperties = {} as TrackingProperties;

    try {
      const newAttendantEncoded = encodeAttendantBlipEmail(email);

      await addUsersTenant();
      await dispatch(addAttendant({ token: key, email: newAttendantEncoded })).unwrap();
      await setPermissions([email]);
    } catch (ex: any) {
      createToastError(TOAST_FAILURE_MESSAGE, ex);

      trackingProperties.status = 'falha';
      trackingProperties.error = ex.message;
      trackingProperties.numAgents = attendants.length;
      trackingProperties.screenName = SubModuleNames.members;
      trackingProperties.warningModal = false;
      Analytics.Track(ANALYTICS.CLICK_SAVE_BUTTON, trackingProperties);
    }
  };

  const setPermissions = async (attendant: string[]) => {
    if (botPermissions?.historyEnabled) {
      await handleAttendantPermissionChange(AttendantPermission.canAccessContactHistory, true, attendant);
    }

    if (botPermissions?.canEditContact) {
      await handleAttendantPermissionChange(AttendantPermission.canEditContact, true, attendant);
    }
  };

  const validateEmail = () => {
    return EMAIL_REGEX.test(email);
  };

  const handleEmailTyping = (event: Event) => {
    const { value } = event.target as HTMLBdsInputElement;
    setEmail(value ?? '');
    setEmailError(false);
  };

  const handleDeleteMember = async (email: string, profileId: string) => {
    try {
      const propertyTrack = { screenName: SubModuleNames.members, type: 'Integrante da equipe' };
      Analytics.Track(ANALYTICS.CLICK_DELETE_ITEM_ICON, propertyTrack);

      const memberId =
        profileId === PROFILE.admin.id
          ? admins.find(admin => admin.email === email)?.id
          : agents.find(agent => agent.email === email)?.id;

      if (memberId) {
        await disassociateMember(memberId, installationId, token);
        dispatch(removeMember(memberId));
      }

      if (profileId === PROFILE.agent.id) {
        const attendantEncoded = encodeToDeleteAttendantBlipEmail(email);
        await dispatch(removeAttendant({ token: key, email: attendantEncoded })).unwrap();
      }
      createToastSuccess(TOAST_SUCCESS, TOAST_SUCCESS_MESSAGE_REMOVE_MEMBER);
    } catch (error: any) {
      createToastError(TOAST_FAILURE_MESSAGE, error);
    }
  };

  const handleProfileChange = (ev: CustomEvent) => {
    setTypeProfile(ev?.detail?.value);
  };

  const clearFields = () => {
    setEmail('');
    setTypeProfile(DEFAULT_TYPE_PROFILE_INITIAL);
  };

  const redirectToSignaturePlan = () => {
    Analytics.Track(Analytics.events.OFFER_BANNER_ATTENDANTS);
    navigate(PAYWALL_ROUTE);
  };

  return (
    <>
      <SubPageHeader showTestButton />

      <ConfigurationsContainer>
        <BdsGrid gap="4" direction="column" justifyContent="center">
          <BdsGrid gap="2" direction="column">
            <BdsTypo>Convide seus membros de equipe para que comecem a utilizar o Blip Go!</BdsTypo>
            <BdsGrid gap="1">
              <BdsInput
                label="E-mail do membro da equipe"
                required
                type="text"
                value={email}
                data-testid="memberEmailInput"
                onBdsInput={handleEmailTyping}
                errorMessage={ConfigurationsModuleTexts.attendants.requiredField}
                requiredErrorMessage={ConfigurationsModuleTexts.attendants.requiredField}
                danger={emailError}
                style={{ width: '23.125rem' }}
              ></BdsInput>
              <BdsSelect
                value={typeProfile}
                label="Permissão"
                placeholder="Selecione"
                onBdsChange={handleProfileChange}
                data-testid="profileSelect"
                style={{ width: '12.5rem' }}
              >
                <BdsSelectOption titleText="Administrador" value={PROFILE.admin.id}>
                  <BdsGrid gap="2" alignItems="center">
                    <BdsIcon name="user-favorite"></BdsIcon>
                    <BdsTypo variant="fs-14">Poderá utilizar todas as funcionalidades</BdsTypo>
                  </BdsGrid>
                </BdsSelectOption>
                <BdsSelectOption titleText="Atendente" value={PROFILE.agent.id} data-testid="attendantOption">
                  <BdsGrid gap="2" alignItems="center">
                    <BdsIcon name="agent"></BdsIcon>
                    <BdsTypo variant="fs-14">Poderá ler e responder conversas</BdsTypo>
                  </BdsGrid>
                </BdsSelectOption>
              </BdsSelect>
              <BdsTooltip
                position="top-center"
                tooltip-text="Limite de atendentes atingido para o plano atual."
                disabled={!showTooltip}
              >
                <BdsButton
                  icon="checkball"
                  onBdsClick={handleAddMember}
                  disabled={!enabledSubmmit || isSubmitting}
                  bdsLoading={isSubmitting}
                  data-testid="inviteMemberBtn"
                >
                  Convidar
                </BdsButton>
              </BdsTooltip>
            </BdsGrid>
          </BdsGrid>
          {attendants.length >= MAX_ATTENDANTS_BASIC && planType !== PlanTypeEnum.Pro && (
            <OfferBanner
              title="Mais atendentes com o plano Pro!"
              textContent="Desbloqueie recursos exclusivos, aumente o limite de atendentes, equipes e conversas mensais."
              textButton="Quero assinar o plano Pro"
              actionButton={redirectToSignaturePlan}
            />
          )}
          <BdsGrid gap="1" direction="column">
            <BdsTypo bold="bold">Membros da equipe</BdsTypo>
            {admins?.map(admin => {
              return (
                <MemberItem
                  key={admin.email}
                  email={admin.email}
                  profileName={admin.profileName}
                  buttonDeleteEnabled={admin.email !== profile.userData?.profile?.email}
                  onDeleteEvent={() => handleDeleteMember(admin.email, PROFILE.admin.id)}
                />
              );
            })}
            {attendants.map(attendant => {
              return (
                <MemberItem
                  key={attendant.email}
                  email={attendant.email}
                  profileName={PROFILE.agent.name}
                  buttonDeleteEnabled={true}
                  onDeleteEvent={() => handleDeleteMember(attendant.email, PROFILE.agent.id)}
                />
              );
            })}
          </BdsGrid>
        </BdsGrid>
      </ConfigurationsContainer>
    </>
  );
};
