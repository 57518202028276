import Switch from 'components/Switch';
import { Shift, WeekDay } from 'objects/types/humamService/AttendanceHour';
import TurnHours from '../TurnHours';
import { MainContainer, TurnContainer } from './styles';

interface AttendanceProps {
  weekDay: WeekDay;
  onToggleActive: (dayOfWeek: string, active: boolean) => void;
  onUpdateShifts: (dayOfWeek: string, shifts: Shift[]) => void;
}

export const Attendance = ({ weekDay, onToggleActive, onUpdateShifts }: AttendanceProps) => {
  const handleSwitchToggle = async (event: Event) => {
    const input = event.currentTarget as HTMLBdsSwitchElement;
    const newActive = await input?.getValue();
    onToggleActive(weekDay.dayOfWeek, newActive);
  };

  const handleShiftChange = (index: number, updatedShift: Shift) => {
    const updatedShifts = weekDay.shifts.map((shift, i) => (i === index ? updatedShift : shift));
    onUpdateShifts(weekDay.dayOfWeek, updatedShifts);
  };

  const handleAddShift = () => {
    if (weekDay.shifts.length < 3) {
      const newShifts = [...weekDay.shifts, { startTime: '', endTime: '' }];
      onUpdateShifts(weekDay.dayOfWeek, newShifts);
    }
  };

  const handleRemoveShift = (index: number) => {
    if (weekDay.shifts.length > 1) {
      const newShifts = weekDay.shifts.filter((_, i) => i !== index);
      onUpdateShifts(weekDay.dayOfWeek, newShifts);
    }
  };

  return (
    <MainContainer $lastInput={false}>
      <Switch
        checked={weekDay.active}
        onChange={handleSwitchToggle}
        name={weekDay.dayOfWeek}
        text={weekDay.dayOfWeek}
        isEmojiSwitch={false}
        dataTestId={`switchDay${weekDay.dayOfWeek}`}
      />
      <TurnContainer>
        {weekDay.shifts.map((shift, index) => (
          <TurnHours
            key={shift.dayofWeek}
            handleAddShift={handleAddShift}
            handleRemoveShift={handleRemoveShift}
            isDisabled={!weekDay.active}
            shiftsQuantity={weekDay.shifts.length}
            shift={shift}
            index={index}
            weekDay={weekDay.dayOfWeek}
            handleShiftChange={handleShiftChange}
          />
        ))}
      </TurnContainer>
    </MainContainer>
  );
};
