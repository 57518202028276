import { Analytics, Logger } from 'infra/adapters';
import './style.scss';
import {
  BdsAlert,
  BdsAlertActions,
  BdsAlertBody,
  BdsAlertHeader,
  BdsButton,
  BdsGrid,
  BdsInput,
  BdsPaper,
  BdsTypo,
} from 'blip-ds/dist/blip-ds-react';
import { AnswerDirectingOptions, AudienceAndVariables } from 'objects/types/ActiveMessages';
import { Template } from 'objects/types/MessageTemplate';
import { useState } from 'react';
import { createToastError, createToastSuccess } from 'services/Toats';
import { dispatchWhatsAppCampaign, getBotsAssociatedWithRouter } from 'services/BlipGoApiService';
import { useSelector } from 'react-redux';
import { useInstallation } from 'redux/slices/InstallationSlice/installationSlice';
import { RootState } from 'redux/store';
import { IInstallationState } from 'redux/slices/InstallationSlice/interface';

export interface ActiveMessagesProViewSendConfirmationProps {
  template: Template | undefined;
  audience: AudienceAndVariables[] | undefined;
  answerDirecting: AnswerDirectingOptions;
  handleSendConfirmationBack: () => void;
  handleSendConfirmationEdit: () => void;
  handleResetSteps: () => void;
  showCancelConfirmation: () => void;
}

export const ActiveMessagesProViewSendConfirmation = ({
  template,
  audience,
  answerDirecting,
  handleSendConfirmationBack,
  handleSendConfirmationEdit,
  handleResetSteps,
  showCancelConfirmation,
}: ActiveMessagesProViewSendConfirmationProps) => {
  const className = 'ActiveMessagesProViewSendConfirmation';
  const [openEditAlert, setOpenEditAlert] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [disableSendMessageButton, setDisableSendMessageButton] = useState(true);
  const [identifierName, setIdentifierName] = useState('');
  const { tenant, router } = useSelector<RootState, IInstallationState>(useInstallation);
  const { desk } = useSelector(useInstallation);

  const handleIdentifierNameChange = (event: Event) => {
    const { value } = event.target as HTMLBdsInputElement;
    setIdentifierName(value ?? '');
    setDisableSendMessageButton(true);
    if (value !== '') {
      setDisableSendMessageButton(false);
    }
  };

  const handleSendMessages = async () => {
    const methodName = 'handleSendMessages';
    try {
      setDisableSendMessageButton(true);
      Analytics.Track(Analytics.events.ACTIVE_MESSAGES_MESSAGE_SEND);
      setIsLoading(true);

      let mainBotShortName = '';
      if (answerDirecting === AnswerDirectingOptions.Chatbot) {
        const bots = await getBotsAssociatedWithRouter(tenant.id, router.shortName);
        mainBotShortName = bots.find(bot => bot.name.search('-Main-') > -1)?.shortName ?? '';
      }

      await dispatchWhatsAppCampaign(
        tenant.id,
        router.shortName,
        mainBotShortName,
        desk.shortName,
        identifierName,
        audience ?? [],
        template ?? ({} as Template),
        answerDirecting,
      );
      createToastSuccess('Campanha criada com sucesso', 'As mensagens estão sendo enviadas.');
      handleResetSteps();
    } catch (ex: any) {
      const errorMessage =
        ex?.data?.Message.search('This name already exists') > -1
          ? 'Nome de disparo já existe.'
          : 'Por favor, tente novamente mais tarde.';
      createToastError('Erro ao enviar mensagens', errorMessage);
      Logger.Error(`error sending active messages: ${ex}`, { methodName, className });
    } finally {
      setIsLoading(false);
      setDisableSendMessageButton(false);
    }
  };

  return (
    <BdsPaper className="active-messages-pro-view-send-confirmation">
      <BdsGrid direction="column" padding="4" margin="x-2">
        <BdsTypo variant="fs-20" bold="bold" margin={false}>
          Resumo do envio
        </BdsTypo>
        <BdsGrid className="edit-box" direction="row" margin="t-2" padding="2" justifyContent="space-between">
          <BdsGrid direction="row" gap="7">
            <BdsGrid direction="column">
              <BdsTypo variant="fs-14" bold="bold" margin={false}>
                Modelo
              </BdsTypo>
              <BdsTypo variant="fs-14" margin={false}>
                {template?.templateId}
              </BdsTypo>
            </BdsGrid>
            <BdsGrid direction="column">
              <BdsTypo variant="fs-14" bold="bold" margin={false}>
                Direcionamento de respostas
              </BdsTypo>
              <BdsTypo variant="fs-14" margin={false}>
                {answerDirecting === AnswerDirectingOptions.Human ? 'Atendimento humano' : 'Início do fluxo'}
              </BdsTypo>
            </BdsGrid>
            <BdsGrid direction="column">
              <BdsTypo variant="fs-14" bold="bold" margin={false}>
                Audiência
              </BdsTypo>
              <BdsTypo variant="fs-14" margin={false}>
                {audience?.length} contatos
              </BdsTypo>
            </BdsGrid>
          </BdsGrid>
          <BdsGrid direction="row" alignItems="center">
            <BdsButton
              variant="text"
              size="short"
              icon="edit"
              color="content"
              onBdsClick={() => setOpenEditAlert(true)}
              data-testid="edit-button"
              disabled={isLoading}
            />
          </BdsGrid>
        </BdsGrid>
        <BdsGrid direction="column" margin="y-4">
          <div className="horizontal-line" />
        </BdsGrid>
        <BdsTypo variant="fs-20" bold="bold" tag="h4" margin={false}>
          Nome do disparo
        </BdsTypo>
        <BdsTypo className="define-send-name" variant="fs-14">
          Defina um nome que será usado para identificação do disparo.
        </BdsTypo>
        <BdsInput
          className="identifier-name"
          value={identifierName}
          required={true}
          requiredErrorMessage="Este campo não pode ficar em branco!"
          placeholder="Nome para identificação (uso interno)"
          onBdsInput={handleIdentifierNameChange}
          disabled={isLoading}
          data-testid="identifier-name"
        />
        <BdsGrid direction="row" gap="2" margin="t-6">
          <BdsButton variant="tertiary" onBdsClick={() => handleSendConfirmationBack()} disabled={isLoading}>
            Voltar
          </BdsButton>
          <BdsButton
            variant="primary"
            onBdsClick={() => handleSendMessages()}
            data-testid="send-message-button"
            disabled={disableSendMessageButton}
            className="send-message-button"
            bdsLoading={isLoading}
          >
            Enviar mensagens
          </BdsButton>
          <BdsGrid className="cancel-button-box" direction="row" justifyContent="flex-end">
            <BdsButton
              variant="text"
              color="content"
              onBdsClick={() => showCancelConfirmation()}
              data-testid="cancel-button"
              disabled={isLoading}
            >
              Cancelar
            </BdsButton>
          </BdsGrid>
        </BdsGrid>
      </BdsGrid>
      <BdsAlert open={openEditAlert}>
        <BdsAlertHeader variant="warning" icon="warning">
          Deseja editar o envio?
        </BdsAlertHeader>
        <BdsAlertBody>
          Ao <b>confirmar</b>, você retornará à etapa de configuração do disparo, e será preciso carregar novamente a
          planilha de audiência.
        </BdsAlertBody>
        <BdsAlertActions>
          <BdsButton variant="secondary" onBdsClick={() => setOpenEditAlert(false)}>
            Voltar
          </BdsButton>
          <BdsButton variant="secondary" onBdsClick={handleSendConfirmationEdit} data-testid="confirm-edit-button">
            Confirmar
          </BdsButton>
        </BdsAlertActions>
      </BdsAlert>
    </BdsPaper>
  );
};
