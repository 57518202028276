import './style.scss';
import {
  BdsAlert,
  BdsAlertActions,
  BdsAlertBody,
  BdsAlertHeader,
  BdsButton,
  BdsChipClickable,
  BdsGrid,
  BdsIcon,
  BdsInput,
  BdsPaper,
  BdsSwitch,
  BdsTooltip,
  BdsTypo,
} from 'blip-ds/dist/blip-ds-react';
import { useEffect, useState } from 'react';
import MainLayout from 'components/MainLayout/MainLayout';
import LayoutContainer from 'components/LayoutContainer/LayoutContainer';
import { ACTIVE_MESSAGES } from 'constants/Routes';
import { useNavigate } from 'react-router-dom';
import { Analytics, Logger } from 'infra/adapters';
import { createToastError, createToastSuccess } from 'services/Toats';
import { dispatchCreateTemplate } from 'services/BlipGoApiService';
import { useSelector } from 'react-redux';
import { useInstallation } from 'redux/slices/InstallationSlice/installationSlice';
import { formatLogMessage } from 'utils/LogMessage';
import { Template, TemplateButton } from 'objects/types/MessageTemplate';

export const ActiveMessagesCreateTemplate = () => {
  const className = 'ActiveMessagesCreateTemplate';
  const [isLoading, setIsLoading] = useState(false);
  const [identifierName, setIdentifierName] = useState('');
  const [identifierNameErrorMessage, setIdentifierNameErrorMessage] = useState('');
  const [disableContinueButton, setDisableContinueButton] = useState(true);
  const [textAndVariables, setTextAndVariables] = useState('');
  const [textAndVariablesError, setTextAndVariablesError] = useState(false);
  const [variablesExample, setVariablesExample] = useState<string[]>([]);
  const [cursorSelectionStart, setCursorSelectionStart] = useState(0);
  const [cursorSelectionEnd, setCursorSelectionEnd] = useState(0);
  const [showButtons, setShowButtons] = useState(false);
  const [toggleSite, setToggleSite] = useState(false);
  const [siteName, setSiteName] = useState('');
  const [siteUrl, setSiteUrl] = useState('');
  const [siteVariableValue, setSiteVariableValue] = useState('');
  const [showSiteVariableValue, setShowSiteVariableValue] = useState(false);
  const [toggleTelephone, setToggleTelephone] = useState(false);
  const [telephoneName, setTelephoneName] = useState('');
  const [telephone, setTelephone] = useState('');
  const [telephoneErrorMessage, setTelephoneErrorMessage] = useState('');
  const [openGoBackConfirmModal, setOpenGoBackConfirmModal] = useState(false);
  const { tenant, router } = useSelector(useInstallation);
  const navigate = useNavigate();

  useEffect(() => {
    validateEnableContinueButton();
  }, [
    identifierName,
    textAndVariables,
    variablesExample,
    showButtons,
    toggleSite,
    siteName,
    siteUrl,
    siteVariableValue,
    toggleTelephone,
    telephoneName,
    telephone,
    telephoneErrorMessage,
  ]);

  useEffect(() => {
    setVariablesExampleArray();
  }, [textAndVariables]);

  const handleIdentifierNameChange = (event: Event) => {
    const { value } = event.target as HTMLBdsInputElement;
    setIdentifierName(value ?? '');

    const sanitizedIdentifierName = sanitizeIdentifierName(value ?? '');
    if (sanitizedIdentifierName !== value) {
      setIdentifierNameErrorMessage('Não é possivel preencher o campo com letras maiúsculas, espaços e hífens');
    } else {
      setIdentifierNameErrorMessage('');
    }
  };

  const sanitizeIdentifierName = (text: string) => {
    return text.replace(/[\s-]/g, '').toLowerCase();
  };

  const handleTextAndVariablesChange = (event: any) => {
    const { value } = event.target as HTMLTextAreaElement;
    setTextAndVariables(value ?? '');
  };

  const handleTextAndVariablesFocus = () => {
    setTextAndVariablesError(false);
  };

  const handleTextAndVariablesBlur = () => {
    if (textAndVariables === '') {
      setTextAndVariablesError(true);
    }
  };

  const handleTextAndVariablesSelection = (event: any) => {
    const textarea = event.target as HTMLTextAreaElement;
    setCursorSelectionStart(textarea.selectionStart ?? 0);
    setCursorSelectionEnd(textarea.selectionEnd ?? 0);
  };

  const handleClickOnBold = () => {
    handleClickOnFormat('*');
  };

  const handleClickOnItalic = () => {
    handleClickOnFormat('_');
  };

  const handleClickOnStrikethrough = () => {
    handleClickOnFormat('~');
  };

  const handleClickOnFormat = (formatChar: string) => {
    const selectedText = textAndVariables.substring(cursorSelectionStart, cursorSelectionEnd);
    const newText =
      textAndVariables.substring(0, cursorSelectionStart) +
      `${formatChar}${selectedText}${formatChar}` +
      textAndVariables.substring(cursorSelectionEnd);
    setTextAndVariables(newText);
    setCursorSelectionStart(cursorSelectionStart - 1 === 0 ? 0 : cursorSelectionStart - 1);
    setCursorSelectionEnd(cursorSelectionEnd + 1 > newText.length ? newText.length : cursorSelectionEnd + 1);
  };

  const handleClickOnAddVariable = () => {
    const selectedText = textAndVariables.substring(cursorSelectionStart, cursorSelectionEnd);
    const newText =
      textAndVariables.substring(0, cursorSelectionStart) +
      `{{${countVariables() + 1}}}${selectedText}` +
      textAndVariables.substring(cursorSelectionEnd);
    setTextAndVariables(newText);
  };

  const countVariables = () => {
    const matches = textAndVariables.match(/{{[^}]*}}/g);
    return matches ? matches.length : 0;
  };

  const setVariablesExampleArray = () => {
    const variablesExampleArray = [];
    for (let index = 0; index < countVariables(); index++) {
      variablesExampleArray[index] = variablesExample[index] ?? '';
    }
    setVariablesExample(variablesExampleArray);
  };

  const handleChangeVariablesExample = (event: any, index: number) => {
    variablesExample[index] = event.target.value;
    setVariablesExample([...variablesExample]);
  };

  const isVariablesExampleFilled = () => {
    for (let index = 0; index < variablesExample.length; index++) {
      if (variablesExample[index] === '') {
        return false;
      }
    }
    return true;
  };

  const handleToggleSite = (ev: CustomEvent) => {
    const value = ev.detail.checked as boolean;
    setToggleSite(value);
  };

  const handleSiteNameChange = (event: Event) => {
    const { value } = event.target as HTMLBdsInputElement;
    setSiteName(value ?? '');
  };

  const handleSiteUrlChange = (event: Event) => {
    const { value } = event.target as HTMLBdsInputElement;
    setSiteUrl(value ?? '');
    checkShowSiteVariableValue(value ?? '');
  };

  const handleSiteVariableValueChange = (event: any) => {
    const { value } = event.target as HTMLBdsInputElement;
    setSiteVariableValue(value ?? '');
  };

  const checkShowSiteVariableValue = (value: string) => {
    const matches = value.match(/{{[^}]*}}/g);
    setShowSiteVariableValue((matches?.length ?? -1) > -1);
  };

  const handleToggleTelephone = (ev: CustomEvent) => {
    const value = ev.detail.checked as boolean;
    setToggleTelephone(value);
  };

  const handleTelephoneNameChange = (event: Event) => {
    const { value } = event.target as HTMLBdsInputElement;
    setTelephoneName(value ?? '');
  };

  const handleTelephoneChange = (event: any) => {
    const { value } = event.target as HTMLBdsInputElement;
    setTelephone(value ?? '');

    const sanitizedTelephone = sanitizeTelephone(value ?? '');
    if (!sanitizedTelephone.startsWith('+')) {
      setTelephoneErrorMessage('É preciso informar o sinal de mais (+) no ínicio do número telefônico.');
    } else if (sanitizedTelephone !== value) {
      setTelephoneErrorMessage('Não é possivel preencher o campo com caracteres inválidos');
    } else {
      setTelephoneErrorMessage('');
    }
  };

  const sanitizeTelephone = (input: string) => {
    let sanitized = input.replace(/[^+\d]/g, '');

    if (sanitized.startsWith('+')) {
      sanitized = '+' + sanitized.slice(1).replace(/[+]/g, '');
    } else {
      sanitized = sanitized.replace(/[+]/g, '');
    }

    return sanitized.slice(0, 20);
  };

  const validateEnableContinueButton = () => {
    if (identifierName === '' || textAndVariables === '' || identifierNameErrorMessage !== '') {
      setDisableContinueButton(true);
      return;
    }

    if (variablesExample.length > 0 && !isVariablesExampleFilled()) {
      setDisableContinueButton(true);
      return;
    }

    if (showButtons) {
      if (toggleSite && (siteName === '' || siteUrl === '' || (showSiteVariableValue && siteVariableValue === ''))) {
        setDisableContinueButton(true);
        return;
      }

      if (toggleTelephone && (telephoneName === '' || telephone === '' || telephoneErrorMessage !== '')) {
        setDisableContinueButton(true);
        return;
      }
    }

    setDisableContinueButton(false);
  };

  const handleBack = () => {
    setOpenGoBackConfirmModal(false);
    Analytics.Track(Analytics.events.ACTIVE_MESSAGES_MESSAGE_BACK, { stage: 'creating_template' });
    navigate(ACTIVE_MESSAGES);
  };

  const escapeNewlines = (text: string) => {
    return text.replace(/\n/g, '\\n');
  };

  const handleOnClickContinue = async () => {
    const methodName = 'handleOnClickContinue';
    try {
      setDisableContinueButton(true);
      setIdentifierNameErrorMessage('');
      Analytics.Track(Analytics.events.ACTIVE_MESSAGES_REGISTER_NEW_MESSAGE);
      setIsLoading(true);

      const template: Template = {
        templateId: '',
        name: identifierName,
        category: 'Marketing',
        language: 'pt-BR',
        status: '',
        body: {
          text: escapeNewlines(textAndVariables),
          variablesExamples: [],
        },
        buttons: [],
      };

      if (variablesExample.length > 0) {
        template.body.variablesExamples = [variablesExample];
      }
      if (siteName !== '') {
        const button: TemplateButton = {
          type: 'Url',
          text: siteName,
          value: siteUrl,
          variableValue: siteVariableValue,
        };
        template.buttons?.push(button);
      }
      if (telephoneName !== '') {
        const button: TemplateButton = {
          type: 'PhoneNumber',
          text: telephoneName,
          value: telephone,
          variableValue: '',
        };
        template.buttons?.push(button);
      }

      await dispatchCreateTemplate(tenant.id, router.shortName, template);
      createToastSuccess('Mensagem cadastrada com sucesso', 'Aguarde alguns instantes para validação da mensagem.');
      navigate(ACTIVE_MESSAGES);
    } catch (ex: any) {
      let errorMessage = 'Por favor, tente novamente mais tarde.';
      if (ex?.data?.Message?.search('There is already Portuguese \\(BR\\) content for this template.') > -1) {
        errorMessage = 'Já existe um modelo de mensagem criada com este nome!';
        setIdentifierNameErrorMessage(errorMessage);
      }
      createToastError('Erro ao cadastrar mensagem', errorMessage);
      Logger.Error(formatLogMessage(ex, `Error creating active messages template:.`), {
        methodName,
        className,
      });
    } finally {
      setIsLoading(false);
      setDisableContinueButton(false);
    }
  };

  return (
    <MainLayout>
      <LayoutContainer>
        <BdsGrid
          className="active-messages-create-template"
          direction="column"
          gap="4"
          padding="y-4"
          margin="x-12"
          height="calc(100vh - 4rem)"
        >
          <BdsGrid direction="column" gap="1">
            <BdsTypo variant="fs-20" bold="bold" margin={false}>
              Cadastrar nova mensagem
            </BdsTypo>
            <BdsTypo className="edit-disclaimer" variant="fs-14" margin={false}>
              Toda mensagem que será disparada de forma ativa aos clientes, ou seja, enviada fora de uma conversa,
              precisa ser cadastrada e passar pela aprovação do WhatsApp, seguindo as{' '}
              <a
                className="edit-disclaimer-meta-url"
                target="_blank"
                rel="noopener noreferrer"
                href="https://developers.facebook.com/docs/whatsapp/message-templates/guidelines"
              >
                diretrizes da Meta.
              </a>
            </BdsTypo>
          </BdsGrid>
          <BdsPaper className="container-edit">
            <BdsGrid direction="column" padding="4" margin="x-2">
              <BdsTypo variant="fs-20" bold="bold" margin={false}>
                Nome da mensagem
              </BdsTypo>
              <BdsTypo className="define-send-name" variant="fs-14">
                Defina um nome que será usado para identificação interna.
              </BdsTypo>
              <BdsInput
                className="identifier-name"
                value={identifierName}
                required={true}
                danger={identifierNameErrorMessage !== ''}
                errorMessage={identifierNameErrorMessage}
                requiredErrorMessage="Este campo não pode ficar em branco!"
                placeholder="Nome para identificação"
                onBdsInput={handleIdentifierNameChange}
                maxlength={1024}
                disabled={isLoading}
                data-testid="identifier-name"
                title="Use letras minúsculas, número ou underline"
              />
              <BdsGrid className="container-edit-box" direction="column" gap="2" margin="t-4" padding="t-4">
                <BdsTypo variant="fs-20" bold="bold" margin={false}>
                  Conteúdo da mensagem
                </BdsTypo>
                <BdsGrid direction="row" gap="8">
                  <BdsGrid direction="column">
                    <BdsGrid className="edit-box" direction="column" padding="3" gap="1">
                      <BdsGrid direction="column">
                        <BdsTypo variant="fs-14" margin={false} bold="semi-bold">
                          Digite o texto da mensagem
                        </BdsTypo>
                        <textarea
                          className={'text-and-variables' + (textAndVariablesError ? ' error' : '')}
                          rows={4}
                          value={textAndVariables}
                          required={true}
                          placeholder="Ex.: Olá {{1}}, podemos retomar nosso contato?"
                          onChange={handleTextAndVariablesChange}
                          disabled={isLoading}
                          data-testid="text-and-variables"
                          maxLength={1024}
                          onMouseUp={handleTextAndVariablesSelection}
                          onKeyUp={handleTextAndVariablesSelection}
                          onFocus={handleTextAndVariablesFocus}
                          onBlur={handleTextAndVariablesBlur}
                        />
                        {textAndVariablesError && (
                          <BdsGrid gap="half">
                            <BdsIcon
                              name="info"
                              theme="outline"
                              size="x-small"
                              className="text-and-variables-error-icon"
                            />
                            <BdsTypo variant="fs-12" margin={false} className="text-and-variables-error-text">
                              Este campo não pode ficar em branco!
                            </BdsTypo>
                          </BdsGrid>
                        )}
                      </BdsGrid>
                      <BdsGrid direction="row" gap="half">
                        <BdsChipClickable
                          color="outline"
                          clickable={true}
                          onChipClickableClick={handleClickOnBold}
                          data-testid="chip-clickable-bold"
                        >
                          <BdsIcon name="text-style-bold" theme="outline" size="x-small" />
                        </BdsChipClickable>
                        <BdsChipClickable
                          color="outline"
                          clickable={true}
                          onChipClickableClick={handleClickOnItalic}
                          data-testid="chip-clickable-italic"
                        >
                          <BdsIcon name="text-style-italic" theme="outline" size="x-small" />
                        </BdsChipClickable>
                        <BdsChipClickable
                          color="outline"
                          clickable={true}
                          onChipClickableClick={handleClickOnStrikethrough}
                          data-testid="chip-clickable-strikethrough"
                        >
                          <BdsIcon name="text-style-strikethrough" theme="outline" size="x-small" />
                        </BdsChipClickable>
                        <BdsChipClickable
                          color="outline"
                          clickable={true}
                          onChipClickableClick={handleClickOnAddVariable}
                          data-testid="chip-clickable-variable"
                        >
                          +variável
                        </BdsChipClickable>
                      </BdsGrid>

                      {variablesExample.length > 0 && (
                        <BdsGrid direction="column" margin="t-2">
                          {variablesExample.map(
                            (_, index) =>
                              (index + 1) % 2 !== 0 && (
                                <BdsGrid key={index} direction="row" margin="b-1" justifyContent="space-between">
                                  <BdsGrid direction="row">
                                    <BdsTypo
                                      className="variable-example-text"
                                      variant="fs-14"
                                      bold="bold"
                                      margin={false}
                                    >
                                      &#123;&#123;{index + 1}&#125;&#125;:
                                    </BdsTypo>
                                    <BdsInput
                                      className="variable-example-input"
                                      required={true}
                                      requiredErrorMessage="Este campo não pode ficar em branco!"
                                      value={variablesExample[index]}
                                      placeholder="Insira um exemplo"
                                      disabled={isLoading}
                                      onBdsInput={event => handleChangeVariablesExample(event, index)}
                                      data-testid={'variable-example-input-' + index}
                                    />
                                  </BdsGrid>
                                  {index + 1 < variablesExample.length && (
                                    <BdsGrid direction="row">
                                      <BdsTypo
                                        className="variable-example-text"
                                        variant="fs-14"
                                        bold="bold"
                                        margin={false}
                                      >
                                        &#123;&#123;{index + 2}&#125;&#125;:
                                      </BdsTypo>
                                      <BdsInput
                                        className="variable-example-input"
                                        required={true}
                                        requiredErrorMessage="Este campo não pode ficar em branco!"
                                        value={variablesExample[index + 1]}
                                        placeholder="Insira um exemplo"
                                        onBdsInput={event => handleChangeVariablesExample(event, index + 1)}
                                        data-testid={'variable-example-input-' + (index + 1)}
                                      />
                                    </BdsGrid>
                                  )}
                                </BdsGrid>
                              ),
                          )}
                        </BdsGrid>
                      )}
                    </BdsGrid>
                    <BdsGrid className="edit-buttons-box" direction="column" padding="3" gap="1">
                      {showButtons && (
                        <>
                          <BdsGrid direction="row" justifyContent="space-between">
                            <BdsTypo variant="fs-14" bold="bold" margin={false}>
                              Defina os botões de ação
                            </BdsTypo>
                            <BdsIcon
                              className="edit-buttons-close"
                              name="close"
                              theme="outline"
                              size="small"
                              onClick={() => setShowButtons(false)}
                            />
                          </BdsGrid>
                          <BdsGrid direction="column">
                            <BdsGrid direction="row" gap="half">
                              <BdsSwitch
                                data-testid="toggle-site"
                                name="toggle-site"
                                refer=""
                                size="short"
                                checked={toggleSite}
                                onBdsChange={handleToggleSite}
                              />
                              <BdsTypo variant="fs-14" bold="bold" margin={false}>
                                Usar botão para site
                              </BdsTypo>
                            </BdsGrid>
                            {toggleSite && (
                              <BdsGrid direction="column" gap="1" margin="t-1">
                                <BdsInput
                                  value={siteName}
                                  label="Nome do botão"
                                  required={true}
                                  requiredErrorMessage="Este campo não pode ficar em branco!"
                                  placeholder="Ex.: Acesse o site"
                                  onBdsInput={handleSiteNameChange}
                                  maxlength={20}
                                  disabled={isLoading}
                                  data-testid="site-name"
                                />
                                <BdsGrid direction="row">
                                  <BdsInput
                                    value={siteUrl}
                                    label="URL"
                                    required={true}
                                    requiredErrorMessage="Este campo não pode ficar em branco!"
                                    placeholder="http://example.com"
                                    onBdsInput={handleSiteUrlChange}
                                    maxlength={2000}
                                    disabled={isLoading}
                                    data-testid="site-url"
                                  />
                                  <BdsTooltip
                                    className="url-info-tooltip"
                                    position="bottom-left"
                                    tooltip-text="Você pode usar uma variável para criar um link personalizado.<br><br>Exemplo: https://example.com?teste={{1}}"
                                  >
                                    <BdsIcon className="url-info" name="info" theme="solid" size="small" />
                                  </BdsTooltip>
                                </BdsGrid>
                                {showSiteVariableValue && (
                                  <BdsGrid direction="row">
                                    <BdsTypo
                                      className="variable-example-text"
                                      variant="fs-14"
                                      bold="bold"
                                      margin={false}
                                    >
                                      &#123;&#123;1&#125;&#125;:
                                    </BdsTypo>
                                    <BdsInput
                                      required={true}
                                      requiredErrorMessage="Este campo não pode ficar em branco!"
                                      value={siteVariableValue}
                                      placeholder="Insira um exemplo"
                                      onBdsInput={event => handleSiteVariableValueChange(event)}
                                      data-testid="url-variable-value"
                                      disabled={isLoading}
                                    />
                                  </BdsGrid>
                                )}
                              </BdsGrid>
                            )}
                          </BdsGrid>
                          <BdsGrid direction="column" margin="t-1">
                            <BdsGrid direction="row" gap="half">
                              <BdsSwitch
                                data-testid="toggle-telephone"
                                name="toggle-telephone"
                                refer=""
                                size="short"
                                checked={toggleTelephone}
                                onBdsChange={handleToggleTelephone}
                              />
                              <BdsTypo variant="fs-14" bold="bold" margin={false}>
                                Usar botão para telefone
                              </BdsTypo>
                            </BdsGrid>
                            {toggleTelephone && (
                              <BdsGrid direction="row" gap="1" margin="t-1">
                                <BdsInput
                                  value={telephoneName}
                                  label="Nome do botão"
                                  required={true}
                                  requiredErrorMessage="Este campo não pode ficar em branco!"
                                  placeholder="Ex.: Ligar agora"
                                  onBdsInput={handleTelephoneNameChange}
                                  maxlength={20}
                                  disabled={isLoading}
                                  data-testid="telephone-name"
                                />
                                <BdsInput
                                  value={telephone}
                                  label="Telefone"
                                  required={true}
                                  requiredErrorMessage="Este campo não pode ficar em branco!"
                                  placeholder="+5541987654321"
                                  onBdsInput={handleTelephoneChange}
                                  danger={telephoneErrorMessage !== ''}
                                  errorMessage={telephoneErrorMessage}
                                  maxlength={20}
                                  disabled={isLoading}
                                  data-testid="telephone"
                                />
                              </BdsGrid>
                            )}
                          </BdsGrid>
                        </>
                      )}
                      {!showButtons && (
                        <BdsGrid
                          className="edit-buttons"
                          direction="row"
                          gap="1"
                          padding="2"
                          justifyContent="center"
                          onClick={() => setShowButtons(true)}
                          data-testid="edit-buttons"
                        >
                          <BdsIcon className="edit-buttons-icon" name="plus" theme="outline" size="small" />
                          <BdsTypo className="edit-buttons-text" variant="fs-14" bold="bold" margin={false}>
                            Adicionar botões (opcional)
                          </BdsTypo>
                        </BdsGrid>
                      )}
                    </BdsGrid>
                  </BdsGrid>
                  <BdsGrid direction="column" padding="y-half">
                    <BdsTypo variant="fs-16" bold="bold" margin={false}>
                      Entenda as variáveis de texto
                    </BdsTypo>
                    <BdsTypo className="example-table-disclaimer" variant="fs-14" margin={false}>
                      Os parâmetros <b>&#123;&#123;variavel&#125;&#125;</b> no texto serão preenchidos com dados
                      fornecidos no momento do envio ao cliente. Por exemplo, uma mensagem com duas variáveis poderia
                      ser preenchida dessa forma:
                    </BdsTypo>
                    <BdsGrid className="example-table" direction="row" margin="t-2">
                      <div className="message-preview-table-example-column">
                        <div className="message-preview-table-example-column-header">
                          <BdsTypo variant="fs-12">telefone</BdsTypo>
                        </div>
                        <div className="message-preview-table-example-column-body">
                          <BdsTypo variant="fs-12">+5541987654321</BdsTypo>
                        </div>
                      </div>
                      <div className="message-preview-table-example-column">
                        <div className="message-preview-table-example-column-header">
                          <BdsTypo variant="fs-12">variável 1</BdsTypo>
                        </div>
                        <div className="message-preview-table-example-column-body">
                          <BdsTypo variant="fs-12">Maria José</BdsTypo>
                        </div>
                      </div>
                      <div className="message-preview-table-example-column">
                        <div className="message-preview-table-example-column-header">
                          <BdsTypo variant="fs-12">variável 2</BdsTypo>
                        </div>
                        <div className="message-preview-table-example-column-body">
                          <BdsTypo variant="fs-12">empréstimo</BdsTypo>
                        </div>
                      </div>
                    </BdsGrid>
                    <BdsGrid
                      className="preview-template"
                      direction="column"
                      margin="t-2"
                      padding="2"
                      justifyContent="space-between"
                    >
                      <BdsTypo className="preview-template-not-parsed" variant="fs-12" margin={false}>
                        Olá <span className="preview-template-variable">&#123;&#123;variavel1&#125;&#125;</span>, vamos
                        falar sobre o{' '}
                        <span className="preview-template-variable">&#123;&#123;variavel2&#125;&#125;</span>?
                      </BdsTypo>
                      <BdsTypo className="preview-template-parsed" variant="fs-14" margin={false}>
                        Olá <span className="preview-template-variable">Maria José</span>, vamos falar sobre o{' '}
                        <span className="preview-template-variable">empréstimo</span>?
                      </BdsTypo>
                    </BdsGrid>
                  </BdsGrid>
                </BdsGrid>
              </BdsGrid>
              <BdsGrid direction="row" gap="2" margin="t-4">
                <BdsButton
                  variant="tertiary"
                  onBdsClick={() => setOpenGoBackConfirmModal(true)}
                  data-testid="back-button"
                >
                  Voltar
                </BdsButton>
                <BdsButton
                  variant="primary"
                  onBdsClick={handleOnClickContinue}
                  disabled={disableContinueButton}
                  data-testid="continue-button"
                >
                  Cadastrar nova mensagem
                </BdsButton>
              </BdsGrid>
            </BdsGrid>
          </BdsPaper>
        </BdsGrid>
        <BdsAlert open={openGoBackConfirmModal}>
          <BdsAlertHeader variant="warning" icon="warning">
            Deseja mesmo voltar?
          </BdsAlertHeader>
          <BdsAlertBody>
            Ao <b>confirmar</b>, você perderá todo o progresso e precisará preencher novamente as informações.
          </BdsAlertBody>
          <BdsAlertActions>
            <BdsButton variant="secondary" onBdsClick={() => setOpenGoBackConfirmModal(false)}>
              Voltar
            </BdsButton>
            <BdsButton variant="secondary" onBdsClick={handleBack} data-testid="confirm-back-button">
              Confirmar
            </BdsButton>
          </BdsAlertActions>
        </BdsAlert>
      </LayoutContainer>
    </MainLayout>
  );
};
