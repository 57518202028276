import { LARGE_MOBILE_SCREEN } from 'constants/MediaQueryResolutions';
import styled from 'styled-components';

export const ReviewContractInfoSubitems = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-top: 1rem;
`;

export const ReviewInfoCardContainer = styled.div`
  margin: 1rem 1.5rem 1.5rem 1.5rem;
  max-width: 33rem;
  cursor: pointer;

  @media screen and (max-width: ${LARGE_MOBILE_SCREEN}) {
    width: 100%;
    margin: 0.5rem;
  }
`;

export const ReviewContractInfoObservations = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  gap: 0.25rem;
`;
