import { yupResolver } from '@hookform/resolvers/yup';
import { BdsButton, BdsGrid, BdsTypo } from 'blip-ds/dist/blip-ds-react';
import { NUMBER_ATTENDANTS } from 'constants/SelfOnboard';
import { Analytics } from 'infra/adapters';
import { PersonalizeServiceOnboarding } from 'objects/types/OnboardingOptions';
import { personalizeServiceOnboardingSchema } from 'pages/Onboarding/validations/OnboardingValidation';
import { FormProvider, useForm } from 'react-hook-form';
import { CheckBoxOnboarding } from '../CheckBoxOnboarding/CheckBoxOnboarding';
import { RadioButtonOnboarding } from '../RadioButtonOnboarding/RadioButtonOnboarding';
import { PersonalizeServiceContainer } from './styles';

interface IProps {
  nextPageEvent: () => void;
  setPersonalizeService: (data: PersonalizeServiceOnboarding) => void;
}

export const PersonalizeService = ({ nextPageEvent, setPersonalizeService }: IProps) => {
  const methods = useForm<PersonalizeServiceOnboarding>({
    resolver: yupResolver(personalizeServiceOnboardingSchema),
    defaultValues: {
      attendants: {
        onlyOne: false,
        twoToFour: false,
        fourToSeven: false,
        moreThanSeven: false,
      },
      selectedFeatures: ['', ''],
    },
  });

  const { handleSubmit, control, clearErrors, setValue } = methods;

  const onSubmit = (data: PersonalizeServiceOnboarding) => {
    const objectiveFunctions = data.selectedFeatures?.map(feat => {
      if (feat !== '') {
        return `${feat}`;
      }
    });

    const selectedAttendants = Object.entries(data.attendants)
      .filter(([key, value]) => value === true)
      .map(([key]) => key);

    const numberOfAttendants = NUMBER_ATTENDANTS.filter(item => selectedAttendants.includes(item.option)).map(
      item => item.text,
    );

    setPersonalizeService(data);
    Analytics.Track(Analytics.events.SETUP_INSTALLATION_CUSTOMIZE_SERVICE, {
      objectives: objectiveFunctions.filter(item => item !== undefined).join(', '),
      nbAttendants: numberOfAttendants.join(', '),
    });

    nextPageEvent();
  };

  const handleAttendantsChange = (selectedValue: string) => {
    if (selectedValue) {
      clearErrors('attendants');
      setValue('attendants', {
        onlyOne: selectedValue === 'onlyOne',
        twoToFour: selectedValue === 'twoToFour',
        fourToSeven: selectedValue === 'fourToSeven',
        moreThanSeven: selectedValue === 'moreThanSeven',
      });
    }
  };

  return (
    <PersonalizeServiceContainer>
      <BdsGrid direction="column" gap="4">
        <FormProvider {...methods}>
          <BdsGrid direction="column">
            <BdsTypo bold="bold" variant="fs-32" tag="h2">
              Personalize o seu atendimento
            </BdsTypo>
            <BdsTypo bold="regular" variant="fs-16">
              Iremos deixar tudo pré configurado e pronto para o uso.
            </BdsTypo>
          </BdsGrid>

          <BdsGrid direction="column" gap="3">
            <BdsGrid direction="column" gap="1">
              <BdsTypo bold="bold" variant="fs-14">
                Quantas pessoas irão atender?
              </BdsTypo>
              <BdsGrid direction="column">
                <RadioButtonOnboarding
                  control={control}
                  name="attendants"
                  options={[
                    { label: 'Somente eu', value: 'onlyOne' },
                    { label: '2 a 4', value: 'twoToFour' },
                    { label: '4 a 7', value: 'fourToSeven' },
                    { label: 'Mais de 7', value: 'moreThanSeven' },
                  ]}
                  dataTestId="attendants-radio"
                  onChangeParam={handleAttendantsChange}
                />
              </BdsGrid>
            </BdsGrid>

            <BdsGrid direction="column" gap="1">
              <BdsTypo bold="bold" variant="fs-14">
                Quais recursos pretende utilizar no atendimento?
              </BdsTypo>
              <CheckBoxOnboarding
                control={control}
                name="selectedFeatures[0]"
                value="Equipes de atendimento separadas por assuntos"
                dataTestId="selectedFeatures-1"
                icon="agent"
                text="Equipes de atendimento separadas por assuntos"
              />
              <CheckBoxOnboarding
                control={control}
                name="selectedFeatures[1]"
                value="Perguntas e respostas predefinidas (FAQ)"
                dataTestId="selectedFeatures-2"
                icon="faq"
                text="Perguntas e respostas predefinidas (FAQ)"
              />
            </BdsGrid>
          </BdsGrid>

          <BdsGrid margin="t-4" direction="row-reverse" justifyContent="space-between">
            <BdsButton onBdsClick={() => handleSubmit(onSubmit)()} data-testid="finish-configuration">
              Finalizar Configuração
            </BdsButton>
          </BdsGrid>
        </FormProvider>
      </BdsGrid>
    </PersonalizeServiceContainer>
  );
};
