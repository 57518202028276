import React, { useEffect, useState } from 'react';
import { getMessages } from 'services/BlipGoApiService';
import { useSelector } from 'react-redux';
import { useInstallation } from 'redux/slices/InstallationSlice/installationSlice';
import { MessagesDto } from 'objects/types/MessagesResponse';
import { BdsGrid, BdsLoadingSpinner } from 'blip-ds/dist/blip-ds-react';
import './style.scss';
import { LoadingContainerChannels } from 'pages/Channels/styles';
import NotificationBanner from '../NotificationBanner/NotificationBanner';
import MessagesList from './MessagesList';

interface ChatHistoryProps {
  contactIdentity: string;
}

const ChatHistory: React.FC<ChatHistoryProps> = ({ contactIdentity }) => {
  const [messages, setMessages] = useState<MessagesDto>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { tenant, router } = useSelector(useInstallation);

  useEffect(() => {
    async function fetchMessages() {
      setIsLoading(true);
      const messagesResponse = await getMessages(tenant.id, router.shortName, contactIdentity);
      if (messagesResponse) {
        setMessages(messagesResponse);
      }
      setIsLoading(false);
    }

    fetchMessages();
  }, [contactIdentity]);

  return (
    <>
      {isLoading ? (
        <BdsGrid alignItems="center" justifyContent="center" height="70vh">
          <LoadingContainerChannels>
            <BdsLoadingSpinner color="main" size="small" data-testid="loading-channels" />
          </LoadingContainerChannels>
        </BdsGrid>
      ) : (
        <BdsGrid className="messages-container" direction="column" height="76vh">
          <NotificationBanner key={contactIdentity} message="A conversa contém o histórico dos últimos 90 dias" />
          {messages && messages.items && <MessagesList messages={messages.items} />}
        </BdsGrid>
      )}
    </>
  );
};

export default ChatHistory;
