import { BdsGrid, BdsPaper, BdsTypo } from 'blip-ds/dist/blip-ds-react';
import { ReviewContractInfoObservations, ReviewContractInfoSubitems, ReviewInfoCardContainer } from './styles';
import { Bold } from 'blip-ds/dist/types/components/typo/typo';

interface IInfoCard {
  subitems: string[];
  title: string;
  subtitle1?: string;
  subtitle2?: string;
  subtitle3?: string;
  subtitle1And2Color?: string;
  subitemFontWeigth: Bold;
  observations?: string[];
  cardColor: string;
}

export const InfoCard = ({
  subitems,
  title,
  subtitle1,
  subtitle2,
  subtitle3,
  subtitle1And2Color,
  subitemFontWeigth,
  observations,
  cardColor,
}: IInfoCard) => {
  return (
    <BdsPaper elevation="static" style={{ backgroundColor: cardColor }} key={title}>
      <ReviewInfoCardContainer>
        <BdsTypo variant="fs-24" bold="bold">
          {title}
        </BdsTypo>
        <ReviewContractInfoSubitems>
          {subtitle1 && (
            <>
              <BdsGrid direction="row" alignItems="flex-end">
                <BdsTypo variant="fs-40" bold="extra-bold" margin={false} style={{ color: subtitle1And2Color }}>
                  {subtitle1}
                </BdsTypo>
                <BdsTypo
                  variant="fs-24"
                  bold="extra-bold"
                  margin={false}
                  style={{ color: subtitle1And2Color, paddingBottom: '4px' }}
                >
                  {subtitle2}
                </BdsTypo>
              </BdsGrid>
              <BdsTypo variant="fs-16" bold="bold" margin={false} style={{ marginBottom: '24px' }}>
                {subtitle3}
              </BdsTypo>
            </>
          )}
          {subitems.map(subitem => {
            return (
              <BdsTypo variant="fs-14" bold={subitemFontWeigth} key={subitem} data-testid="content-subitem">
                {subitem}
              </BdsTypo>
            );
          })}
        </ReviewContractInfoSubitems>
        {observations ? (
          <ReviewContractInfoObservations>
            {observations?.map(observation => {
              return (
                <BdsTypo variant="fs-12" italic key={observation} data-testid="observation-item">
                  {observation}
                </BdsTypo>
              );
            })}
          </ReviewContractInfoObservations>
        ) : null}
      </ReviewInfoCardContainer>
    </BdsPaper>
  );
};
