import { BdsGrid, BdsIcon, BdsStep, BdsStepper, BdsTypo } from 'blip-ds/dist/blip-ds-react';
import { CONTRACT_STEPPER } from 'constants/MetaActivation';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { AgentInfo } from '../AgentInfo/AgentInfo';
import { CompanyInfo } from '../CompanyInfo/CompanyInfo';
import { ReviewPlan } from '../ReviewPlan/ReviewPlan';
import { SafeEnvironmentContainer, StepperContainer } from './styles';

export const Signature = () => {
  const [showContent, setShowContent] = useState(false);
  const [step, setStep] = useState(CONTRACT_STEPPER.companyData);
  const [stepper, setStepper] = useState<HTMLBdsStepperElement | undefined>(undefined);

  const location = useLocation();
  const { planType } = location.state;

  useEffect(() => {
    updateStepper();
  }, []);

  const isTabActive = (index: number) => {
    return 0 === index;
  };

  const updateStepper = () => {
    if (location?.state) {
      const { step } = location.state;
      if (step) {
        setStep(step);
      }
    }

    const stepperComponent = document.querySelector('bds-stepper');
    setStepper(stepperComponent as HTMLBdsStepperElement);
    setShowContent(true);
  };

  const handleNextPage = async () => {
    stepper?.setActiveStep(step + 1);
    stepper?.setCompletedStep(step);
    setStep(step + 1);
    stepper?.setActiveStep(step + 1);
  };

  const handlePreviousStepClick = async () => {
    stepper?.setActiveStep(step - 1);
    setStep(step - 1);
    stepper?.setActiveStep(step - 1);
  };

  const StepContent = () => {
    switch (step) {
      case CONTRACT_STEPPER.companyData:
        return <CompanyInfo nextPageEvent={handleNextPage} />;
      case CONTRACT_STEPPER.companyAgent:
        return <AgentInfo nextPageEvent={handleNextPage} previousPageEvent={handlePreviousStepClick} />;
      default:
        return <ReviewPlan signaturePlanType={planType} previousPageEvent={handlePreviousStepClick} />;
    }
  };

  return (
    <StepperContainer>
      <BdsStepper>
        <BdsStep active={isTabActive(CONTRACT_STEPPER.companyData)} data-testid="company-data-tab">
          Dados empresa
        </BdsStep>
        <BdsStep active={isTabActive(CONTRACT_STEPPER.companyAgent)} data-testid="agent-info-tab">
          Dados representante
        </BdsStep>
        <BdsStep active={isTabActive(CONTRACT_STEPPER.brief)} data-testid="contract-info-tab">
          Resumo
        </BdsStep>
        <BdsGrid>
          <SafeEnvironmentContainer>
            <BdsGrid alignItems="center" gap="1" padding="2">
              <BdsIcon theme="outline" name="data-security" color="#1E6BF1" />
              <BdsTypo variant="fs-14" bold="semi-bold">
                Seus dados estão seguros! As informações fornecidas aqui permanecerão privadas.
              </BdsTypo>
            </BdsGrid>
          </SafeEnvironmentContainer>
        </BdsGrid>
      </BdsStepper>
      <BdsGrid justifyContent="center" padding="2" class="fade-in">
        {showContent && <StepContent />}
      </BdsGrid>
    </StepperContainer>
  );
};
